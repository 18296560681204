<template>
  <v-overlay :model-value="true" class="align-center justify-center">
    <v-progress-circular
      color="white"
      size="64"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { updateProfile } from "@/util/MemberProfile";
import { defineProps } from "vue";

let liff;
if (process.env.VUE_APP_ENV == "production") {
  liff = (await import("@line/liff")).default;
} else {
  liff = (await import("@/util/mock/liff.js")).default;
  liff.mockSetUserId("id124");
}
import axios from "axios";

const props = defineProps(["next", "liffId", "loadData"]);
const router = useRouter();
const route = useRoute();

var userId = "";
var pictureUrl = "";
var displayName = "";

props.loadData(route.params.shopId).then((data) => {
  const liffId = props.liffId(data);
  liff
    .init({ liffId: liffId, withLoginOnExternalBrowser: true })
    .then(() => {
      if (!liff.isLoggedIn()) {
        console.log(window.location.href);
        liff.login({ redirectUri: window.location.href });
      }
      // accessToken = liff.getAccessToken();
      // idToken = liff.getIDToken();
      liff.getProfile().then((response) => {
        userId = response.userId;
        pictureUrl = response.pictureUrl;
        displayName = response.displayName;
        loadValidMember(route.params.shopId, userId).then((data) => {
          if (data.is_valid) {
            updateProfile(data.member_id, {
              line_display_name: displayName,
              line_picture_url: pictureUrl,
            }).then(() => {
              router.push(props.next(route, data.member_id));
            });
          } else {
            router.push({
              name: "membershipRegister",
              params: { shopId: route.params.shopId },
              query: {
                lineId: userId,
                nextPage: props.next(route, data.member_id).name,
              },
            });
          }
        });
      });
    })
    .catch((error) => {
      console.log(error);
    });
});

async function loadValidMember(shopId, lineUserId) {
  return axios
    .get(
      `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/valid_member`,
      { params: { shop_id: shopId, line_user_id: lineUserId } }
    )
    .then((response) => {
      return response.data;
    });
}
</script>
