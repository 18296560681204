<template>
  <v-sheet class="text-start">
    <page-head title="Create Topic" :breadcrumb="['Class', 'Topics', 'Create']">
    </page-head>
    <v-form class="ma-3" ref="form" @submit.prevent="onFormSubmit">
      <h2 class="my-5">Topic</h2>
      <v-text-field
        label="Name"
        :rules="RULES.notEmpty"
        v-model="nameValue"
        variant="outlined"
      ></v-text-field>
      <v-text-field
        label="Period (min.)"
        :rules="RULES.digit"
        v-model="periodValue"
        variant="outlined"
      ></v-text-field>
      <v-text-field
        label="Max Paricipant"
        :rules="RULES.digit"
        v-model="maxParicipantValue"
        variant="outlined"
      ></v-text-field>
      <v-btn class="mt-2" type="submit" variant="outlined" block color="primary"
        >Create</v-btn
      >
    </v-form>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { authAxios } from "@/util/Axios";
import { ref } from "vue";
import { RULES } from "@/util/Form";
import { useRouter } from "vue-router";

const axios = authAxios();
const router = useRouter();

const form = ref(null);
const nameValue = ref("");
const periodValue = ref("");
const maxParicipantValue = ref("");

async function onFormSubmit() {
  const { valid } = await form.value.validate();
  if (valid) {
    submitCreateTopic(
      nameValue.value,
      periodValue.value,
      maxParicipantValue.value
    ).then(() => {
      router.go(-1);
    });
  }
}

function submitCreateTopic(name, period, maxParicipant) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/topic`,
    data: { name, period, max_participant: maxParicipant },
  });
}
</script>
