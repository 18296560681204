<template>
  <v-date-input
    variant="outlined"
    prepend-icon=""
    prepend-inner-icon="$calendar"
    v-model="modelValue"
  ></v-date-input>
</template>

<script setup>
import moment from "moment";
import { defineProps, toRef, watch } from "vue";
const props = defineProps(["patchKey", "default", "updateFunc"]);
const modelValue = toRef(props.default);

watch(modelValue, (newValue) => {
  props
    .updateFunc(props.patchKey, moment(newValue).format("YYYY-MM-DD"))
    .then(() => {});
});
</script>
