<template>
  <app-bar title="Purchase Summary" :member-id="$route.params.memberId" />
  <h1 class="ma-5">
    Your purchase has been completed. Thank you for your support.
  </h1>
  <v-btn
    class="mt-10 mx-5"
    :to="{
      name: 'memberLanding',
      params: {
        memberId: $route.params.memberId,
        shopId: $route.params.shopId,
      },
    }"
    >Back to Member Page</v-btn
  >
</template>

<script setup>
import axios from "axios";
import { useRoute } from "vue-router";
import AppBar from "@/components/AppBar.vue";

const route = useRoute();

loadPaymentStatus(route.params.memberId, route.params.orderNo).then(
  (response) => {
    console.log(response);
  }
);

async function loadPaymentStatus(memberId, orderNo) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/wallet_top_up/id-${orderNo}`,
  });
}
</script>
