import axios from "axios";
import { inject } from "vue";

export function authAxios() {
  const cookies = inject("$cookies");
  const authHeader = {
    Authorization: `Bearer ${cookies.get("access-token")}`,
    "toa-rim-shop": cookies.get("toa-rim-shop"),
  };

  const instance = axios.create({
    headers: authHeader,
  });
  return instance;
}
