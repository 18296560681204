<template>
  <router-link :to="props.to" class="link">
    <v-sheet
      v-ripple
      class="d-flex align-center mx-5 my-3 pa-3 rounded-xl"
      border="opacity-50 sm"
      elevation="2"
    >
      <v-icon :icon="props.icon" class="menu-icon me-3"></v-icon>
      <div class="text-start flex-grow-1">
        <p class="title">{{ props.title }}</p>
        <p>{{ props.description }}</p>
      </div>
      <v-icon icon="mdi-chevron-right" class="arrow"></v-icon>
    </v-sheet>
  </router-link>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["title", "description", "icon", "to"]);
</script>

<style scoped>
.link {
  text-decoration: none;
}

.menu-icon {
  font-size: 3rem;
}

.title {
  font-weight: 600;
}

.arrow {
  font-size: 2rem;
}
</style>
