<template>
  <v-checkbox v-model="modelValue"></v-checkbox>
</template>

<script setup>
import { defineProps, toRef, watch } from "vue";
const props = defineProps(["patchKey", "default", "updateFunc"]);
const modelValue = toRef(props.default);

watch(modelValue, (newValue) => {
  props.updateFunc(props.patchKey, newValue).then(() => {});
});
</script>
