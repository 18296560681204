<template>
  <v-sheet class="text-start">
    <page-head title="Shops">
      <template v-slot>
        <v-btn
          variant="outlined"
          color="primary"
          :to="{ name: 'hqAddUserShop' }"
          ><v-icon>mdi-plus</v-icon>New Shop</v-btn
        >
      </template>
    </page-head>
    <v-data-table-server
      :items="dataTable.items"
      :items-per-page="dataTable.itemsPerPage"
      :items-length="dataTable.totalItems"
      :headers="dataTable.headers"
      :loading="dataTable.isLoading"
      :items-per-page-options="dataTable.itemsPerPageOptions"
      @update:options="updateShop"
    >
      <template v-slot:[`item.name`]="{ value, item }">
        <a :href="`/hq/shop/id-${item.id}`">{{ value }}</a></template
      ></v-data-table-server
    >
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { reactive } from "vue";
import { authAxios } from "@/util/Axios";
import { DEFAULT_DATATABLE } from "@/util/List";

const axios = authAxios();
const dataTable = reactive({
  headers: [
    { title: "Name", key: "name", align: "start", sortable: false },
    {
      title: "Booking (7 days)",
      key: "booking_count",
      align: "start",
      sortable: false,
    },
    {
      title: "Expired Date",
      key: "next_billing_date",
      align: "start",
      sortable: false,
    },
  ],
  ...DEFAULT_DATATABLE,
});

function updateShop({ page, itemsPerPage }) {
  dataTable.isLoading = true;
  loadShop(page, itemsPerPage).then((response) => {
    dataTable.isLoading = false;
    var items = [];
    response.data.data.forEach((shop) => {
      items.push({
        id: shop.shop_id,
        name: shop.name,
        booking_count: shop.booking_count,
        next_billing_date: shop.next_billing_date,
      });
    });
    dataTable.items = items;
    dataTable.totalItems = response.data.total_items;
  });
}

function loadShop(page, length) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/shop/vuetify`,
    params: { page: page, length: length, search: "" },
  });
}
</script>
