<template>
  <v-navigation-drawer
    class="pa-0"
    width="300"
    :temporary="$vuetify.display.mdAndDown"
  >
    <v-img
      :src="require('@/assets/img/logo_booking_hoz.svg')"
      class="mx-15 my-10"
    />
    <v-list class="text-start" color="red-darken-4">
      <v-list-item title="Dashboard" :to="{ name: 'hqDashboard' }">
        <template #prepend>
          <v-icon icon="mdi-home-variant-outline" />
        </template>
      </v-list-item>
      <v-list-item title="Shops" :to="{ name: 'hqShopList' }">
        <template #prepend>
          <v-icon icon="mdi-store-outline" />
        </template>
      </v-list-item>
      <v-list-item title="Users" :to="{ name: 'hqUserList' }">
        <template #prepend>
          <v-icon icon="mdi-face-man-outline" />
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
