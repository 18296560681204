<template>
  <v-main
    class="d-flex flex-column justify-content-between main"
    :style="{ background: ui.backgroundColor }"
  >
    <router-view :ui="ui"></router-view>
    <powered-by-code-gears class="mt-auto mb-3"></powered-by-code-gears>
  </v-main>
</template>

<script setup>
import PoweredByCodeGears from "@/components/PoweredByCodeGears.vue";
import { useUi } from "@/util/ShopUi";
import { useRoute } from "vue-router";
import { ref } from "vue";

const route = useRoute();
const ui = ref({});
useUi(route.params.shopId).then((responseUi) => {
  ui.value = responseUi;
});
</script>
