<template>
  <v-sheet class="text-start" v-if="promotionGroup">
    <page-head
      :title="promotionGroup.name"
      sub-head="promotion groups"
      :breadcrumb="['Market', 'Promotion Groups', promotionGroup.name]"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input
              label="ID"
              :default="promotionGroup.id"
              :readonly="true"
            ></patch-input>
            <patch-input
              label="Name"
              patch-key="name"
              :default="promotionGroup.name"
              :update-func="onUpdate"
            ></patch-input>
            <patch-select
              label="Packages"
              patch-key="packages"
              :items="packages"
              :default="promotionGroup.packages"
              :update-func="onUpdate"
              multiple
              chips
            ></patch-select>
          </v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";
import PatchSelect from "@/components/admin_theme/patch/PatchSelect.vue";

import { authAxios } from "@/util/Axios";

import { ref } from "vue";
import { useRoute } from "vue-router";

const tab = ref(null);

const axios = authAxios();
const route = useRoute();

const promotionGroup = ref(null);
const promotionGroupId = route.params.promotionGroupId;

const packages = ref([]);

loadPackages().then((response) => {
  var result = [];
  response.data.data.forEach((p) => {
    result.push({ title: p.name, value: p.id });
  });
  packages.value = [...result];
});

function loadPackages() {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/sale_package/`,
  });
}

loadPromotionGroup(promotionGroupId).then((response) => {
  promotionGroup.value = response.data;
});

function loadPromotionGroup(promotionGroupId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/promotion_group/id-${promotionGroupId}`,
  });
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/promotion_group/id-${promotionGroupId}`,
    data: { [key]: value },
  });
}
</script>
