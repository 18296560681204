<template>
  <v-dialog :model-value="props.value" width="auto" min-width="340" persistent>
    <v-card
      prepend-icon="mdi-plus-box-outline"
      title="Create New Class"
      v-if="branchItems && topicItems && instructorItems"
    >
      <v-sheet class="d-flex flex-column ma-3">
        <v-form @submit.prevent ref="form">
          <v-select
            v-model="selectedBranch"
            :rules="RULES.select"
            variant="outlined"
            label="Branch"
            prepend-inner-icon="mdi-store-outline"
            :items="branchItems"
            item-title="name"
            item-value="id"
          ></v-select>
          <v-select
            v-model="selectedTopic"
            :rules="RULES.select"
            variant="outlined"
            label="Topic"
            prepend-inner-icon="mdi-human-male-board"
            :items="topicItems"
            item-title="name"
            item-value="id"
          ></v-select>
          <v-select
            v-model="selectedInstructor"
            :rules="RULES.select"
            variant="outlined"
            label="Instructor"
            prepend-inner-icon="mdi-card-account-details"
            :items="instructorItems"
            item-title="name"
            item-value="id"
          ></v-select>
          <v-date-input
            v-model="selectedDate"
            :rules="RULES.notEmpty"
            prepend-icon=""
            prepend-inner-icon="$calendar"
            label="Date"
            variant="outlined"
            :allowed-dates="futureDate"
          ></v-date-input>
          <time-input
            v-model="selectedTime"
            :rules="RULES.notEmpty"
            label="Time"
            prepend-inner-icon="mdi-clock-time-four-outline"
            variant="outlined"
          ></time-input>
        </v-form>
      </v-sheet>
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Cancel"
          @click="
            emits('close');
            clearSelected();
          "
        ></v-btn>
        <v-btn text="Update" @click="onNewClassSubmit"></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import TimeInput from "@/components/TimeInput.vue";
import BackendConnector from "@/util/BackendConnector";
import moment from "moment";
import { ref, defineProps, defineEmits } from "vue";
import { authAxios } from "@/util/Axios";
import { RULES } from "@/util/Form";

const axios = authAxios();

const props = defineProps(["value"]);
const emits = defineEmits(["close", "newClass"]);

const form = ref(null);

const branchItems = ref(null);
const topicItems = ref(null);
const instructorItems = ref(null);

const selectedBranch = ref("");
const selectedTopic = ref("");
const selectedInstructor = ref("");
const selectedDate = ref(null);
const selectedTime = ref(null);

async function onNewClassSubmit() {
  const { valid } = await form.value.validate();
  console.log("valid: ", valid);
  if (valid) {
    updateNewClassroom(
      selectedBranch.value,
      selectedTopic.value,
      selectedInstructor.value,
      selectedDate.value,
      selectedTime.value
    ).then((response) => {
      clearSelected();
      console.log(response);
      emits("newClass");
    });
  }
}

function clearSelected() {
  selectedBranch.value = "";
  selectedTopic.value = "";
  selectedInstructor.value = "";
  selectedDate.value = null;
  selectedTime.value = null;
}

BackendConnector.loadBranch().then((response) => {
  branchItems.value = response.data.data;
});

BackendConnector.loadTopic().then((response) => {
  topicItems.value = response.data.data;
});

BackendConnector.loadInstructor().then((response) => {
  instructorItems.value = response.data.data;
});

function updateNewClassroom(branchId, topicId, instructorId, date, time) {
  console.log(date, time);
  let dt = joinDateTime(date, time);
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/`,
    data: {
      branch_id: branchId,
      topic_id: topicId,
      instructor_id: instructorId,
      datetime: moment(dt).format("YYYY-MM-DD HH:mm"),
    },
    withCredentials: true,
  });
}

function joinDateTime(d, t) {
  console.log(d, t);
  let dStr = moment(d).format("YYYY-MM-DD");
  let dtStr = dStr + " " + t;
  console.log(dtStr);
  return moment(dtStr, "YYYY-MM-DD HH:mm");
}

function futureDate(date) {
  let askDate = moment(date);
  return askDate > moment().add(-1, "day");
}
</script>
