<template>
  <time-input
    v-model="modelValue"
    prepend-inner-icon="mdi-clock-time-four-outline"
    variant="outlined"
    format="24hr"
  ></time-input>
</template>

<script setup>
import TimeInput from "@/components/TimeInput.vue";
import { defineProps, toRef, watch } from "vue";

const props = defineProps(["patchKey", "default", "updateFunc"]);
const modelValue = toRef(props.default);

watch(modelValue, (newValue) => {
  console.log("New Value: ", newValue);
  props.updateFunc(props.patchKey, newValue).then(() => {});
});
</script>
