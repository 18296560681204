<template>
  <router-link :to="props.to" class="no-decoration">
    <v-sheet
      class="dashboard-link pa-5 ma-3 text-start rounded-lg d-flex align-center"
    >
      <v-icon :icon="props.icon" class="me-3 icon"></v-icon>
      <div class="flex-grow-1">
        <p class="title">{{ props.title }}</p>
        <v-divider />
        <p class="description">{{ props.description }}</p>
      </div>
    </v-sheet>
  </router-link>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["title", "description", "to", "icon"]);
</script>

<style scoped>
.no-decoration {
  text-decoration: none;
}

.dashboard-link {
  border: 2px solid navy;
  color: navy;
}

.icon {
  font-size: 3rem;
}
.title {
  font-size: 1.5rem;
}
.description {
  font-size: 0.8rem;
}
</style>
