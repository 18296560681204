<template>
  <v-sheet class="pa-5">
    <div class="d-flex">
      <div>
        <h2>{{ props.member.name }}</h2>
        <div>{{ props.member.phone }} - {{ props.member.email }}</div>
      </div>
      <v-btn
        class="ms-auto"
        variant="outlined"
        :to="{
          name: 'frontdeskMemberDetail',
          params: { memberId: props.member.id },
        }"
        ><v-icon icon="mdi-account-details-outline"></v-icon>More</v-btn
      >
    </div>
    <div v-if="props.shop.feature_enable.member_wallet">
      <v-divider></v-divider>
      <p class="my-3">
        <strong>Wallet:</strong> {{ props.member.wallet_amount }} THB
        <v-btn @click="isWalletAmountDialogShow = true">
          <v-icon icon="mdi-pencil-outline"></v-icon>
          Adjust
        </v-btn>
      </p>
      <v-divider></v-divider>
    </div>
    <v-data-table
      :headers="fundTable.headers"
      :items="fundTable.items"
      @click:row="onFundClick"
    >
      <template v-slot:bottom></template>
    </v-data-table>
  </v-sheet>
  <v-dialog v-model="isDialogShow" width="auto" min-width="340" persistent>
    <v-card class="pa-5" prepend-icon="mdi-progress-star" title="Fund Update">
      <v-sheet class="d-flex flex-column">
        <v-text-field
          label="Add / Remove Point"
          prepend-inner-icon="mdi-progress-star"
          variant="outlined"
          type="number"
          v-model="updatedValue"
          hide-details
        ></v-text-field>
        <p class="mb-10 ms-1">Current Value: {{ selectedFund.value }}</p>
        <v-date-input
          label="New Expired Date"
          prepend-icon=""
          prepend-inner-icon="$calendar"
          variant="outlined"
          v-model="updatedDate"
          hide-details
        ></v-date-input>
        <p class="mb-10 ms-1">
          Current Expired Date: {{ selectedFund.expire_date }}
        </p>
        <v-date-input
          label="New Unlimited End Date"
          prepend-icon=""
          prepend-inner-icon="$calendar"
          variant="outlined"
          v-model="updatedUnlimited"
          hide-details
        ></v-date-input>
        <p class="mb-10 ms-1">
          Current Unlimited End Date:
          {{ selectedFund.unlimited_access_end_date }}
        </p>
      </v-sheet>
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Cancel"
          @click="isDialogShow = false"
        ></v-btn>
        <v-btn text="Update" @click="onUpdateFundSubmit"></v-btn>
      </template>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="isWalletAmountDialogShow"
    persistent
    width="auto"
    min-width="340"
    ><v-card
      class="pa-5"
      title="Wallet Amount Update"
      prepend-icon="mdi-pencil-outline"
    >
      <v-text-field
        label="Add / Remove Amount (THB)"
        prepend-inner-icon="mdi-cash"
        variant="outlined"
        type="number"
        v-model="updatedWalletAmount"
        hide-details
      ></v-text-field>
      <p class="mb-10 ms-1">
        Current Wallet Amount:
        {{ props.member.wallet_amount }} THB
      </p>
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Cancel"
          @click="isWalletAmountDialogShow = false"
        ></v-btn>
        <v-btn text="Update" @click="onAdjustWalletAmountSubmit"></v-btn>
      </template> </v-card
  ></v-dialog>
</template>

<script setup>
import { authAxios } from "@/util/Axios";
import moment from "moment";
import { ref, defineProps, reactive, defineEmits } from "vue";

const axios = authAxios();
const props = defineProps(["member", "shop"]);
const emits = defineEmits(["updateSubmit"]);
const isDialogShow = ref(false);
const isWalletAmountDialogShow = ref(false);
const fundTable = reactive({
  headers: [
    { title: "Name", key: "name", sortable: false },
    { title: "Value", key: "value", sortable: false },
    { title: "Expired Date", key: "expire_date", sortable: false },
    {
      title: "Unlimited End Date",
      key: "unlimited_access_end_date",
      sortable: false,
    },
  ],
  items: props.member.fund,
  isLoading: false,
});

const selectedFund = ref({});
const updatedValue = ref(0);
const updatedDate = ref(null);
const updatedUnlimited = ref(null);
const updatedWalletAmount = ref(0);

function onFundClick(event, row) {
  isDialogShow.value = true;
  selectedFund.value = row.item;
}

function onAdjustWalletAmountSubmit() {
  isWalletAmountDialogShow.value = false;
  adjustWalletAmount(props.member.id, updatedWalletAmount.value).then(() => {
    emits("updateSubmit");
  });
}

function onUpdateFundSubmit() {
  isDialogShow.value = false;
  console.log("Fund ID: ", selectedFund.value.id);
  updateFund(
    props.member.id,
    selectedFund.value.id,
    updatedValue.value,
    updatedDate.value,
    updatedUnlimited.value
  ).then(() => {
    emits("updateSubmit");
  });
}

function adjustWalletAmount(memberId, amount) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/adjust_wallet`,
    data: { amount },
  });
}

function updateFund(memberId, fundId, fundValue, fundExpire, unlimitedEndDate) {
  let data = { fund: { [fundId]: { value: fundValue } } };
  if (fundExpire) {
    data["fund"][fundId]["expire_date"] =
      moment(fundExpire).format("YYYY-MM-DD");
  }
  if (unlimitedEndDate) {
    data["fund"][fundId]["unlimited_access_end_date"] =
      moment(unlimitedEndDate).format("YYYY-MM-DD");
  }
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/fund`,
    data: data,
  });
}
</script>
