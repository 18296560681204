<template>
  <v-sheet class="text-start" v-if="instructor">
    <page-head
      :title="instructor.name"
      sub-head="instructors"
      :breadcrumb="['Class', 'Instructors', instructor.name]"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input
              label="ID"
              :default="instructor.id"
              :readonly="true"
            ></patch-input>
            <patch-input
              label="Name"
              patch-key="name"
              :default="instructor.name"
              :update-func="onUpdate"
            ></patch-input>
          </v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";

import { authAxios } from "@/util/Axios";

import { ref } from "vue";
import { useRoute } from "vue-router";

const tab = ref(null);

const axios = authAxios();
const route = useRoute();

const instructor = ref(null);

loadInstructor(route.params.instructorId).then((response) => {
  instructor.value = response.data;
});

function loadInstructor(instructorId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/instructor/id-${instructorId}`,
  });
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/instructor/id-${route.params.instructorId}`,
    data: { [key]: value },
  });
}
</script>
