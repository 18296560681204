<template>
  <v-main
    class="d-flex flex-column mx-sm-auto mx-md-auto mx-lg-auto mx-xl-auto main"
    max-width="620"
    min-width="340"
    width="100%"
    :style="{ backgroundColor: ui.backgroundColor }"
  >
    <router-view :ui="ui" />
    <powered-by-code-gears class="mt-auto mb-3 pt-5" />
  </v-main>
</template>

<script setup>
import { useUi } from "@/util/ShopUi.js";
import { ref } from "vue";
import { useRoute } from "vue-router";
import PoweredByCodeGears from "@/components/PoweredByCodeGears.vue";

const route = useRoute();
const ui = ref({});
useUi(route.params.shopId).then((responseUi) => {
  ui.value = responseUi;
});
</script>
