<template>
  <app-bar title="Purchase Package" :member-id="$route.params.memberId" />
  <div v-for="group in promotionGroups" :key="group.name">
    <h3 class="text-start mx-5 mt-5">
      {{ group.name }}
    </h3>
    <v-slide-group clas="ma-5">
      <v-slide-group-item
        v-for="salePackage in group.sale_packages"
        :key="salePackage.id"
      >
        <div class="pa-5">
          <v-sheet
            class="text-start d-flex align-end justify-end promotion-sheet"
            :style="{
              backgroundImage: `url( ${imageLinkWithRandom(
                salePackage.image_link
              )} )`,
            }"
            width="240"
            height="240"
            rounded="xl"
          >
            <v-btn
              class="mx-5 my-3 pa-3"
              size="xl"
              min-width="150"
              @click="onPackageBuyClicked($event, salePackage)"
            >
              {{ salePackage.price }} THB
            </v-btn>
          </v-sheet>
          <h5 class="text-start promotion-title-text mt-2">
            {{ salePackage.name }}
          </h5>
          <p class="text-start promotion-description-text">
            {{ salePackage.description }}
          </p>
        </div>
      </v-slide-group-item>
    </v-slide-group>
  </div>
  <pay-solution-hidden-form ref="paySoForm" :pay-so-data="paySoData" />
</template>

<script setup>
import AppBar from "@/components/AppBar.vue";
import PaySolutionHiddenForm from "@/components/PaySolutionHiddenForm.vue";

import axios from "axios";
import PaySolution from "@/util/PaySolution";
import { reactive, ref, defineProps } from "vue";
import { useRoute } from "vue-router";
import { useProfile } from "@/util/MemberProfile";

const props = defineProps({
  ui: {
    type: Object,
    default() {
      return {};
    },
  },
});
console.log(props);
const route = useRoute();
const promotionGroups = ref([]);
const paySoData = reactive({
  orderNo: "",
  merchantId: "",
  customerEmail: "",
  productDetail: "",
  netPrice: 0,
});
const profile = ref({});
useProfile(route.params.memberId).then((response) => {
  profile.value = response;
});
const paySoForm = ref(null);

function onPackageBuyClicked(event, salePackage) {
  console.log("Click Package: ", salePackage);
  let orderNo = PaySolution.generateOrderNo();
  paySoData.orderNo = orderNo;
  paySoData.merchantId = props.ui.paySolutionMerchantId;
  paySoData.customerEmail = profile.value.email || "empty@email.com";
  paySoData.productDetail = salePackage.name;
  paySoData.netPrice = salePackage.price;
  paySoData.returnUrl = generateReturnUrl(
    route.params.shopId,
    route.params.memberId,
    orderNo
  );
  saveTransaction(route.params.memberId, orderNo, salePackage.id).then(() => {
    paySoForm.value.$el.submit();
  });
}

const randomNum = Math.random();
function imageLinkWithRandom(link) {
  return `${link}?a=${randomNum}`;
}

function generateReturnUrl(shopId, memberId, orderNo) {
  return `${window.location.origin}/membership/shop-${shopId}/id-${memberId}/order/id-${orderNo}/summary`;
}

loadPromotion(route.params.shopId, route.params.memberId).then((response) => {
  promotionGroups.value = response.data;
  console.log(response);
});

function saveTransaction(memberId, refNo, packageId) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/buy_package`,
    data: {
      reference_no: refNo,
      package_id: packageId,
    },
  });
}

function loadPromotion(shopId, memberId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/promotion_group/promotion_page`,
    params: { shop_id: shopId, member_id: memberId },
  });
}
</script>

<style scoped>
.promotion-sheet {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.promotion-title-text {
  font-size: 1rem;
}

.promotion-description-text {
  width: 240px;
}
</style>
