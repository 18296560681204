<template>
  <v-sheet class="text-start">
    <page-head
      title="Create Resource"
      :breadcrumb="['Facility', 'Resources', 'Create']"
    >
    </page-head>
    <v-form class="ma-3" ref="form" @submit.prevent="onFormSubmit">
      <h2 class="my-5">Resource</h2>
      <v-text-field
        label="Name"
        :rules="RULES.notEmpty"
        v-model="nameValue"
        variant="outlined"
      ></v-text-field>
      <v-select
        label="Branch"
        :rule="RULES.notEmpty"
        v-model="branchValue"
        :items="branchs"
        variant="outlined"
      ></v-select>
      <v-text-field
        label="Amount"
        :rules="RULES.digit"
        v-model="amountValue"
        variant="outlined"
      ></v-text-field>
      <v-btn class="mt-2" type="submit" variant="outlined" block color="primary"
        >Create</v-btn
      >
    </v-form>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import BackendConnector from "@/util/BackendConnector";
import { authAxios } from "@/util/Axios";
import { reactive, ref } from "vue";
import { RULES } from "@/util/Form";
import { useRouter } from "vue-router";
import { useUserProfile } from "@/util/UserProfile";

const axios = authAxios();
const router = useRouter();

const form = ref(null);
const nameValue = ref("");
const branchValue = ref(null);
const amountValue = ref("");
const branchs = reactive([]);

const profile = reactive({});
useUserProfile().then((response) => {
  Object.assign(profile, response);
  BackendConnector.loadBranchs(profile.currentShopId).then((response) => {
    Object.assign(branchs, [...response]);
  });
});

async function onFormSubmit() {
  const { valid } = await form.value.validate();
  if (valid) {
    submitCreateResource(
      nameValue.value,
      branchValue.value,
      amountValue.value
    ).then(() => {
      router.go(-1);
    });
  }
}

function submitCreateResource(name, branch, amount) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/resource`,
    data: { name, branch_id: branch, amount },
  });
}
</script>
