<template>
  <v-sheet elevation="2" class="pa-5" rounded="xl">
    <v-form ref="form" @submit.prevent="onFormSubmit">
      <h3 class="my-5">กรอกข้อมูลเพื่อของรับ Demo</h3>
      <v-text-field
        label="ชื่อ / นามสกุล"
        variant="outlined"
        v-model="nameValue"
        :rules="RULES.notEmpty"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        label="ชื่อร้าน / บริษัท"
        variant="outlined"
        v-model="organizationValue"
        :rules="RULES.notEmpty"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        label="เบอร์โทรติดต่อกลับ"
        variant="outlined"
        v-model="phoneValue"
        :rules="RULES.notEmpty"
        class="mb-3"
      ></v-text-field>
      <v-btn
        type="submit"
        block
        color="red-darken-4"
        variant="flat"
        size="large"
        >ขอรับ Demo</v-btn
      >
      <p class="text-center mt-2">ทีมงานของเราจะรีบติดต่อกลับท่านโดยเร็ว</p>
    </v-form>
  </v-sheet>
</template>

<script setup>
import axios from "axios";
import { RULES } from "@/util/Form";
import { ref } from "vue";
import { event } from "vue-gtag";

const form = ref(null);
const nameValue = ref(null);
const organizationValue = ref(null);
const phoneValue = ref(null);

async function onFormSubmit(e) {
  const { valid } = await form.value.validate();
  if (valid) {
    submitContact(nameValue.value, organizationValue.value, phoneValue.value);
    event("form_submit", { form_name: "contact-form" });
  }
  e.preventDefault();
}

function submitContact(name, organization, phone) {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("organization", organization);
  formData.append("phone", phone);
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/submit_contact`,
    data: formData,
  });
}
</script>
