<template>
  <v-main class="main">
    <v-container class="d-flex flex-column pa-0" fluid style="height: 100vh">
      <v-sheet class="d-flex flex-wrap pa-sm-2 align-center">
        <router-link :to="{ name: 'webHome' }">
          <v-img
            :src="require('@/assets/img/logo_booking_hoz.svg')"
            max-width="196"
            min-width="196"
            class="mx-5 mt-3 mt-sm-0"
          ></v-img>
        </router-link>
        <div class="my-5 d-flex">
          <v-list-item
            title="หน้าหลัก"
            :to="{ name: 'webHome' }"
            variant="plain"
            color="red-darken-4"
          ></v-list-item>
          <v-list-item
            title="คุณสมบัติ"
            :to="{ name: 'webFeature' }"
            variant="plain"
            color="red-darken-4"
          ></v-list-item>
          <v-list-item
            title="ติดต่อ"
            :to="{ name: 'webContact' }"
            variant="plain"
            color="red-darken-4"
          ></v-list-item>
        </div>
      </v-sheet>
      <router-view></router-view>
    </v-container>
  </v-main>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.main {
  font-family: "Kanit", sans-serif;
}
</style>

<style>
.landing-title {
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 3rem;
}
.landing-description {
  font-weight: 300;
  font-size: 1.2rem;
}
</style>
