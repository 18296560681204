<template>
  <v-app-bar :color="ui.primaryColor" elevation="2">
    <template #prepend>
      <slot name="prepend" />
    </template>
    <v-app-bar-title class="app-bar">
      {{ props.title }}
    </v-app-bar-title>
    <template #append>
      <v-btn icon>
        <v-avatar
          color="surface-variant"
          icon="mdi-account-circle"
          :image="profile.pictureUrl"
        />
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script setup>
import { defineProps, watch } from "vue";
import { useUi } from "@/util/ShopUi.js";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useProfile } from "@/util/MemberProfile.js";

const props = defineProps(["title", "memberId"]);
const route = useRoute();
const ui = ref({});
const profile = ref({ funds: [], pictureUrl: "" });

useUi(route.params.shopId).then((responseUi) => {
  ui.value = responseUi;
});

useProfile(props.memberId)
  .then((responseProfile) => {
    profile.value = responseProfile;
  })
  .catch(() => {
    profile.value = {};
  });

watch(
  () => props.memberId,
  (newValue) => {
    useProfile(newValue)
      .then((responseProfile) => {
        profile.value = responseProfile;
      })
      .catch(() => {
        profile.value = {};
      });
  }
);
</script>

<style scoped>
.app-bar {
  text-align: left;
}
</style>
