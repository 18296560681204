<template>
  <v-text-field
    v-model="modelValue"
    variant="outlined"
    :readonly="props.readonly"
    :disabled="props.readonly"
  >
    <template #append v-if="!props.readonly">
      <v-btn
        @click="onClickUpdate"
        icon="mdi-upload"
        :loading="isLoading"
      ></v-btn>
    </template>
  </v-text-field>
</template>

<script setup>
import { defineProps, toRef, ref } from "vue";
const props = defineProps(["default", "patchKey", "readonly", "updateFunc"]);
const modelValue = toRef(props.default);
const isLoading = ref(false);

function onClickUpdate() {
  isLoading.value = true;
  props.updateFunc(props.patchKey, modelValue.value).then(() => {
    isLoading.value = false;
  });
}
</script>
