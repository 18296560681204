import axios from "axios";
import { authAxios } from "./Axios";
import moment from "moment";

export default {
  async loadShopDetail(shopId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/shop/id-${shopId}`,
    });
  },
  async loadClassroomInitialData(shopId) {
    return axios
      .get(
        `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/classroom_initial_data`,
        { params: { shop_id: shopId } }
      )
      .then((response) => {
        return response.data;
      });
  },
  async loadFacilityConfig(shopId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/facility_config/shop-${shopId}`,
    });
  },
  async loadClassroom(classId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/id-${classId}`,
    });
  },
  async loadBranch() {
    const axios = authAxios();
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/shop_branch/`,
      withCredentials: true,
    });
  },
  async loadTopic() {
    const axios = authAxios();
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/topic/`,
      withCredentials: true,
    });
  },
  async loadInstructor() {
    const axios = authAxios();
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/instructor/`,
      withCredentials: true,
    });
  },
  async loadMemberFund() {
    const axios = authAxios();
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/member_fund/`,
    });
  },
  async loadResource() {
    const axios = authAxios();
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/resource/`,
    });
  },
  async loadTopUpHistory(memberId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/wallet_top_up/history`,
    });
  },
  async loadWalletAdjustLog(memberId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/wallet_top_up/adjust_log`,
    });
  },
  async loadBookingHistory(memberId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_booking/history`,
      params: { member_id: memberId },
    });
  },
  async loadFacilityBookingHistory(memberId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/facility_booking/history`,
      params: { member_id: memberId },
    });
  },
  async loadPurchasingHistory(memberId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/purchase_report/history`,
      params: { member_id: memberId },
    });
  },
  async loadAdjustedFundLog(memberId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/fund_log`,
    });
  },
  async loadBranchs(shopId) {
    return axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/branchs`, {
        params: { shop_id: shopId },
      })
      .then((response) => {
        var result = [];
        response.data.forEach((item) => {
          result.push({ title: item.name, value: item.branch_id });
        });
        return result;
      });
  },
  async loadFacilityDates(branchId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/facility_event/dates`,
      params: { branch_id: branchId },
    });
  },
  async loadFacilityResources(branchId, date) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/facility_event/facility_resources`,
      params: { branch_id: branchId, date: moment(date).format("YYYY-MM-DD") },
    });
  },
  async loadFacilityTimes(resourceId, branchId, date, amount = 1) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/facility_event/times`,
      params: {
        branch_id: branchId,
        resource_id: resourceId,
        date: moment(date).format("YYYY-MM-DD"),
        amount,
      },
    });
  },
  async makeClassBooking(eventId, memberId) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/make_booking`,
      data: { event_id: eventId, member_id: memberId },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert(error.response.data.detail);
      });
  },
  async makeFacilityBooking(
    branchId,
    resourceId,
    memberId,
    date,
    startTime,
    endTime,
    amount
  ) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_BACKEND_URL}/api/facility_event/make_booking`,
      data: {
        branch_id: branchId,
        resource_id: resourceId,
        member_id: memberId,
        date,
        start_time: startTime,
        end_time: endTime,
        amount,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert(error.response.data.detail);
      });
  },
  async doLogin(email, password) {
    let formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_BACKEND_URL}/auth/login`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
