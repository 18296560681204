<template>
  <v-container fluid class="fill-height pa-0">
    <div class="logo-image ma-5">
      <v-img :src="require('@/assets/img/logo_booking_hoz.svg')"></v-img>
    </div>
    <v-row class="fill-height" no-gutters>
      <v-col cols="6" class="d-none d-md-flex left-col flex-column">
        <v-spacer></v-spacer>
        <slot name="leftHeader"></slot>
      </v-col>
      <v-col cols="12" md="6" class="right-col d-flex flex-column">
        <v-spacer></v-spacer>
        <v-sheet
          class="py-10 px-3 px-sm-5 mx-3 mx-sm-auto right-form"
          :class="{ limited: smAndUp }"
          rounded="xl"
        >
          <slot name="default"></slot>
        </v-sheet>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { defineProps } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { smAndUp } = useDisplay();
const props = defineProps(["leftBackground", "rightBackgroundColor"]);
</script>

<style scoped>
.logo-image {
  position: absolute;
  top: 0;
  width: 280px;
}

.left-col {
  background-color: #f5fcff;
  background-repeat: no-repeat;
  background-position: center;
  background-image: v-bind("props.leftBackground"),
    url("@/assets/img/login/blue_star.png");
  background-size: 55%, 60%;
}

.right-col {
  background-color: v-bind("rightBackgroundColor");
}

.right-form {
  max-width: 100%;
}

.limited {
  width: 400px;
}
</style>
