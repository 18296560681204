<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* Transition */
.left-slide-enter-from {
  transform: translateX(50px);
  opacity: 0;
}

.left-slide-leave-from,
.left-slide-enter-to {
  transform: translateX(0px);
  opacity: 1;
}

.left-slide-leave-to {
  transform: translateX(-50px);
  opacity: 0;
}

.left-slide-enter-active {
  transition: all 0.3s ease-out;
}

.left-slide-leave-active {
  transition: all 0.3s ease-in;
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.3s ease-in;
}
</style>
