<template>
  <v-sheet class="text-start">
    <page-head title="Instructor" :breadcrumb="['Class', 'Instructor']">
      <template v-slot>
        <v-btn
          variant="outlined"
          color="primary"
          :to="{ name: 'instructorCreate' }"
          ><v-icon>mdi-plus</v-icon>Create Instructor</v-btn
        >
      </template>
    </page-head>
    <v-data-table-server
      :items="dataTable.items"
      :items-per-page="dataTable.itemsPerPage"
      :items-length="dataTable.totalItems"
      :headers="dataTable.headers"
      :loading="dataTable.isLoading"
      :items-per-page-options="dataTable.itemsPerPageOptions"
      @update:options="updateInstructor"
    >
      <template v-slot:[`item.name`]="{ value, item }">
        <a :href="`/management/instructor/id-${item.id}`">
          {{ value }}
        </a></template
      ></v-data-table-server
    >
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { reactive } from "vue";
import { authAxios } from "@/util/Axios";
import { DEFAULT_DATATABLE } from "@/util/List";

const axios = authAxios();
const dataTable = reactive({
  headers: [{ title: "Name", key: "name", align: "start", sortable: false }],
  ...DEFAULT_DATATABLE,
});

function updateInstructor({ page, itemsPerPage }) {
  dataTable.isLoading = true;
  loadInstructor(page, itemsPerPage).then((response) => {
    dataTable.isLoading = false;
    dataTable.items = [...response.data.data];
    dataTable.totalItems = response.data.total_items;
  });
}

function loadInstructor(page, length) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/instructor/vuetify`,
    params: { page: page, length: length, search: "" },
  });
}
</script>
