<template>
  <v-tabs v-model="tab" :fixed-tabs="props.fixedTabs">
    <v-tab value="book">Book</v-tab>
    <v-tab value="purchase">Purchase</v-tab>
    <v-tab value="adjusted">Adjusted</v-tab>
  </v-tabs>

  <v-tabs-window v-model="tab">
    <v-tabs-window-item value="book">
      <v-list class="bg-transparent text-start" lines="two">
        <v-list-item
          v-for="booking in bookingList"
          :key="booking.booking_id"
          :title="`${booking.topic} (${booking.instructor})`"
          :subtitle="moment(booking.datetime).format('MMM DD, YYYY HH:mm')"
        >
          <template #prepend>
            <v-icon icon="mdi-calendar" class="list-prepend-icon"></v-icon>
          </template>
        </v-list-item>
      </v-list>
      <p v-if="bookingList.length == 0" class="mt-5">No Class Booked</p>
    </v-tabs-window-item>
    <v-tabs-window-item value="purchase">
      <v-list class="bg-transparent text-start" lines="two">
        <v-list-item
          v-for="purchase in purchasingList"
          :key="purchase.id"
          :title="purchase.package_name"
          :subtitle="
            moment(purchase.create_local_dt).format('MMM DD, YYYY HH:mm')
          "
        >
          <template #prepend>
            <v-icon
              icon="mdi-credit-card-outline"
              class="list-prepend-icon"
            ></v-icon>
          </template>
        </v-list-item>
      </v-list>
      <p v-if="purchasingList.length == 0" class="mt-5">No Item Purchased</p>
    </v-tabs-window-item>
    <v-tabs-window-item value="adjusted">
      <v-list class="bg-transparent text-start" lines="two">
        <v-list-item
          v-for="adjusted in adjustedList"
          :key="adjusted.id"
          :title="`${adjusted.fund_name} (${adjusted.changing_value})`"
          :subtitle="
            moment(adjusted.create_local_dt).format('MMM DD, YYYY HH:mm')
          "
        >
          <template #prepend>
            <v-icon icon="mdi-update" class="list-prepend-icon"></v-icon>
          </template>
        </v-list-item>
      </v-list>
      <p v-if="purchasingList.length == 0" class="mt-5">No Adjusted Log</p>
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script setup>
import { ref, defineProps } from "vue";
import moment from "moment";
import BackendConnector from "@/util/BackendConnector";

const props = defineProps(["memberId", "fixedTabs"]);
const bookingList = ref([]);
const purchasingList = ref([]);
const adjustedList = ref([]);
const tab = ref(null);

BackendConnector.loadBookingHistory(props.memberId).then((response) => {
  bookingList.value = response.data;
});

BackendConnector.loadPurchasingHistory(props.memberId).then((response) => {
  purchasingList.value = response.data;
});

BackendConnector.loadAdjustedFundLog(props.memberId).then((response) => {
  adjustedList.value = response.data;
});
</script>
