<template>
  <page-head
    title="New Shop"
    sub-head="shops"
    :breadcrumb="['Shops', 'New Shop']"
  >
  </page-head>
  <v-sheet class="text-start ma-3 pa-3">
    <v-form @submit.prevent="onFormSubmit" ref="form">
      <h2 class="my-5">Create user shop and link them</h2>
      <v-text-field
        label="Email"
        variant="outlined"
        v-model="emailValue"
        :rules="RULES.email"
      ></v-text-field>
      <v-text-field
        label="Password"
        variant="outlined"
        v-model="passwordValue"
        :rules="RULES.notEmpty"
      ></v-text-field>
      <v-text-field
        label="Shop ID"
        variant="outlined"
        v-model="shopIdValue"
        :rules="RULES.id"
      ></v-text-field>
      <v-text-field
        label="Shop Name"
        variant="outlined"
        v-model="shopNameValue"
        :rules="RULES.notEmpty"
      ></v-text-field>
      <v-select
        label="Supervisor"
        variant="outlined"
        :items="supervisor"
        v-model="supervisorValue"
        :rules="RULES.select"
      ></v-select>
      <v-btn class="mt-2" type="submit" block>Submit</v-btn>
    </v-form>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { authAxios } from "@/util/Axios";
import { ref } from "vue";
import { RULES } from "@/util/Form";
import { useRouter } from "vue-router";

const router = useRouter();
const axios = authAxios();
const form = ref(null);

const emailValue = ref("");
const passwordValue = ref("");
const shopIdValue = ref("");
const shopNameValue = ref("");
const supervisorValue = ref(null);

async function onFormSubmit() {
  const { valid } = await form.value.validate();
  if (valid) {
    submitCreateUserShopAndLinkThem(
      emailValue.value,
      passwordValue.value,
      shopIdValue.value,
      shopNameValue.value,
      supervisorValue.value
    ).then(() => {
      router.push({ name: "hqShopList" });
    });
  }
}

function submitCreateUserShopAndLinkThem(
  email,
  password,
  shopId,
  shopName,
  supervisor
) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/auth/create_user_shop_and_link_them`,
    data: { email, password, shop_id: shopId, shop_name: shopName, supervisor },
  });
}

const supervisor = [
  {
    title: "Chet (chet.c@codegears.co.th)",
    value: "chet.c@codegears.co.th",
  },
  {
    title: "Thipnaree (thipnaree.h@codegears.co.th)",
    value: "thipnaree.h@codegears.co.th",
  },
];
</script>
