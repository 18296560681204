<template>
  <v-text-field v-model="model" :active="isShow" :focus="onShow" readonly>
    <v-menu
      v-model="isShow"
      :close-on-content-click="false"
      activator="parent"
      transition="scale-transition"
    >
      <v-time-picker
        v-if="isShow"
        v-model="model"
        format="24hr"
        full-width
      ></v-time-picker>
    </v-menu>
  </v-text-field>
</template>

<script setup>
import { ref, defineModel } from "vue";

const model = defineModel();
const isShow = ref(false);

function onShow() {
  isShow.value = true;
}
</script>
