import BackendConnector from "@/util/BackendConnector";

var cached = {};

export async function useUi(shopId) {
  if (cached[shopId] !== undefined) {
    return cached[shopId];
  }
  return BackendConnector.loadClassroomInitialData(shopId).then((data) => {
    var result = {
      primaryColor: data.primary_color,
      secondaryColor: data.secondary_color,
      backgroundColor: data.background_color,
      name: data.shop_name,
      squareLogoLink: data.square_logo_link,
      paySolutionMerchantId: data.paysolution_merchant_id,
    };
    cached[shopId] = { ...result };
    return cached[shopId];
  });
}
