<template>
  <v-dialog :model-value="props.value" width="auto" min-width="340" persistent>
    <v-card title="New Facility Booking" prepend-icon="mdi-plus-box-outline">
      <v-sheet class="d-flex flex-column ma-3">
        <v-form ref="form" @submit.prevent v-if="branchs">
          <auto-complete-member v-model="selectedMember"></auto-complete-member>
          <v-select
            v-model="selectedBranch"
            :rules="RULES.select"
            variant="outlined"
            label="Branch"
            prepend-inner-icon="mdi-store-outline"
            :items="branchs"
            item-title="name"
            item-value="id"
          ></v-select>
          <v-date-input
            :disabled="!stepReach('BRANCH_SELECTED')"
            v-model="selectedDate"
            :rules="RULES.notEmpty"
            prepend-icon=""
            prepend-inner-icon="$calendar"
            label="Date"
            variant="outlined"
            :allowed-dates="allowedDates"
          ></v-date-input>
          <v-select
            :disabled="!stepReach('DATE_SELECTED')"
            v-model="selectedResource"
            :rule="RULES.select"
            variant="outlined"
            label="Resource"
            prepend-inner-icon="mdi-land-plots"
            :items="resources"
          ></v-select>
          <v-number-input
            :disabled="!stepReach('RESOURCE_SELECTED')"
            :reverse="false"
            controlVariant="stacked"
            label="Amount"
            :hideInput="false"
            :inset="false"
            variant="outlined"
            :min="1"
            :max="4"
            v-model="selectedAmount"
          >
            <template #prepend-inner
              ><v-icon
                icon="mdi-checkbox-multiple-blank-outline"
                class="ms-3"
              ></v-icon></template
          ></v-number-input>
          <v-select
            :disabled="!stepReach('AMOUNT_SELECTED')"
            v-model="selectedTime"
            variant="outlined"
            label="Timeslot"
            prepend-inner-icon="mdi-land-plots"
            :items="times"
            multiple
            chips
          >
          </v-select>
        </v-form>
      </v-sheet>
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Cancel"
          @click="emits('cancelBooking')"
        ></v-btn>
        <v-btn
          :disabled="!stepReach('TIME_SELECTED')"
          text="Update"
          @click="onMakeBookingSubmit"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, reactive } from "vue";
import AutoCompleteMember from "./AutoCompleteMember.vue";
import BackendConnector from "@/util/BackendConnector";
import { RULES } from "@/util/Form";
import moment from "moment";

const STEP = [
  "NOTHING_SELECTED",
  "BRANCH_SELECTED",
  "DATE_SELECTED",
  "RESOURCE_SELECTED",
  "AMOUNT_SELECTED",
  "TIME_SELECTED",
];
const currentStep = ref(0);

const props = defineProps(["value"]);
const emits = defineEmits(["makeBooking", "cancelBooking"]);

const branchs = reactive([]);
const allowedDates = reactive([]);
const times = reactive([]);
const resources = reactive([]);

const selectedMember = ref(null);
const selectedBranch = ref(null);
const selectedDate = ref(null);
const selectedResource = ref(null);
const selectedAmount = ref(null);
const selectedTime = ref(null);

watch(selectedBranch, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  selectedDate.value = null;
  selectedResource.value = null;
  selectedAmount.value = null;
  selectedTime.value = null;
  BackendConnector.loadFacilityDates(newValue).then((response) => {
    Object.assign(allowedDates, [...response.data]);
    currentStep.value = STEP.indexOf("BRANCH_SELECTED");
  });
});

watch(selectedDate, (newValue) => {
  currentStep.value = STEP.indexOf("BRANCH_SELECTED");
  selectedResource.value = null;
  selectedAmount.value = null;
  selectedTime.value = null;
  BackendConnector.loadFacilityResources(selectedBranch.value, newValue).then(
    (response) => {
      var result = [];
      response.data.forEach((element) => {
        result.push({ title: element.name, value: element.id });
      });
      Object.assign(resources, [...result]);
      currentStep.value = STEP.indexOf("DATE_SELECTED");
    }
  );
});

watch(selectedResource, () => {
  currentStep.value = STEP.indexOf("RESOURCE_SELECTED");
  selectedAmount.value = null;
  selectedTime.value = null;
});

watch(selectedAmount, (newValue) => {
  if (newValue == 0 || newValue == null) {
    return;
  }
  currentStep.value = STEP.indexOf("TIME_SELECTED");
  selectedTime.value = null;
  BackendConnector.loadFacilityTimes(
    selectedResource.value,
    selectedBranch.value,
    selectedDate.value,
    newValue
  ).then((response) => {
    var result = [];
    response.data[0].forEach((element) => {
      let s_time = moment(element.start_time, "HH:mm:ss").format("HH:mm");
      let e_time = moment(element.end_time, "HH:mm:ss").format("HH:mm");
      result.push({
        title: `${s_time} - ${e_time} (${element.price} THB)`,
        value: [s_time, e_time],
      });
    });
    if (result.length > 0) {
      times.length = 0;
      Object.assign(times, [...result]);
    } else {
      times.length = 0;
    }
    currentStep.value = STEP.indexOf("AMOUNT_SELECTED");
  });
});

watch(selectedTime, () => {
  currentStep.value = STEP.indexOf("TIME_SELECTED");
});

BackendConnector.loadBranch().then((response) => {
  Object.assign(branchs, [...response.data.data]);
});

function onMakeBookingSubmit() {
  let startTime = [];
  let endTime = [];
  selectedTime.value.forEach((slot) => {
    startTime.push(slot[0]);
    endTime.push(slot[1]);
  });
  console.log(startTime, endTime);
  BackendConnector.makeFacilityBooking(
    selectedBranch.value,
    selectedResource.value,
    selectedMember.value,
    moment(selectedDate.value).format("YYYY-MM-DD"),
    startTime,
    endTime,
    selectedAmount.value
  ).then(() => {
    emits("makeBooking");
  });
}

function stepReach(step) {
  return currentStep.value >= STEP.indexOf(step);
}
</script>
