import { inject } from "vue";
import { useRouter } from "vue-router";

export function checkForLoginAndRedirect(redirectTarger) {
  const cookies = inject("$cookies");
  const router = useRouter();

  if (cookies.isKey("access-token") && cookies.isKey("toa-rim-shop")) {
    router.push(redirectTarger);
  }
}

export function logout(redirectTarget, cookies, router) {
  cookies.remove("access-token");
  cookies.remove("toa-rom-shop");
  router.push(redirectTarget);
}
