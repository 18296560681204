<template>
  <div class="outter-card">
    <div
      class="class-card"
      :class="{ active: isSelected, disable: isFullyBook }"
      @click="onSelected"
    >
      <p class="class-time">{{ starTime }} - {{ endTime }}</p>
      <p class="class-topic">{{ props.classData.topic }}</p>
      <p class="class-instructor">{{ props.classData.instructor }}</p>
      <p class="class-participant">
        {{ props.classData.current_participant }} /
        {{ props.classData.max_participant }}
      </p>
    </div>
    <v-btn class="notify-me-btn" v-if="isFullyBook" @click="onNotifyMe">
      <v-icon icon="mdi-bell-outline"></v-icon>Notify Me
    </v-btn>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed, defineProps } from "vue";
import moment from "moment";
import axios from "axios";

const props = defineProps(["classData", "memberId"]);
const isSelected = ref("");
const emit = defineEmits(["picked"]);

function onSelected() {
  if (!isFullyBook.value) {
    emit("picked", props.classData.id);
  }
}

const starTime = computed(() =>
  moment(props.classData.datetime).format("HH:mm")
);

const endTime = computed(() =>
  moment(props.classData.datetime)
    .add(props.classData.period, "m")
    .format("HH:mm")
);

const isFullyBook = computed(
  () => props.classData.current_participant >= props.classData.max_participant
);

function onNotifyMe() {
  callNotifyMe(props.classData.id, props.memberId).then(() => {
    alert("We will notify you when class is available to book.");
  });
}

function callNotifyMe(eventId, memberId) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/notify_on_available`,
    data: { event_id: eventId, member_id: memberId },
  });
}
</script>

<style scoped>
.outter-card {
  position: relative;
}

.active .class-card {
  background-color: lightblue !important;
}

.disable {
  filter: brightness(0.5);
}

.class-card {
  position: relative;
  margin: 1rem 0rem;
  border-left: 5px solid darkolivegreen;
  text-align: left;
  padding: 1rem;
  border-radius: 8px;
  background: #fffafa;
  box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
}

.class-topic {
  font-size: 1.8rem;
  font-weight: 600;
}
.class-time {
  font-size: 2.4rem;
  font-weight: 600;
}

.class-instructor {
  font-size: 1.2rem;
}

.class-participant {
  text-align: right;
}

.notify-me-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
