<template>
  <v-sheet class="text-start">
    <page-head title="Invite Team" :breadcrumb="['Shop', 'Teams', 'Invite']">
    </page-head>
    <v-form class="ma-3" ref="form" @submit.prevent="onFormSubmit">
      <h2 class="my-5">Team</h2>
      <v-text-field
        label="Email"
        :rules="RULES.email"
        v-model="emailValue"
        variant="outlined"
      ></v-text-field>
      <h4>Role</h4>
      <v-checkbox
        label="Admin"
        v-model="roleValue"
        value="admin"
        density="compact"
      ></v-checkbox>
      <v-checkbox
        label="Staff"
        v-model="roleValue"
        value="staff"
        density="compact"
      ></v-checkbox>
      <v-checkbox
        label="Frontdesk"
        v-model="roleValue"
        value="frontdesk"
        density="compact"
      ></v-checkbox>
      <v-btn class="mt-2" type="submit" variant="outlined" block color="primary"
        >Create</v-btn
      >
    </v-form>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { authAxios } from "@/util/Axios";
import { inject, ref } from "vue";
import { RULES } from "@/util/Form";
import { useRouter } from "vue-router";

const cookies = inject("$cookies");
const shopId = cookies.get("toa-rim-shop");

const axios = authAxios();
const router = useRouter();

const form = ref(null);
const emailValue = ref("");
const roleValue = ref([]);

async function onFormSubmit() {
  const { valid } = await form.value.validate();
  if (valid) {
    submitTeamInvite(emailValue.value, roleValue.value).then(() => {
      router.go(-1);
    });
  }
}

function submitTeamInvite(email, role) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/auth/invite_user`,
    data: { shop_id: shopId, email, role },
  });
}
</script>
