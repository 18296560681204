<template>
  <app-bar title="Upcoming" :member-id="$route.params.memberId" />

  <v-list class="bg-transparent text-start" lines="two">
    <v-list-item
      v-for="booking in bookingList"
      :key="booking.booking_id"
      :title="`${booking.topic} (${booking.instructor})`"
      :subtitle="moment(booking.datetime).format('MMM DD, YYYY HH:mm')"
    >
      <template #prepend>
        <v-icon icon="mdi-calendar" class="list-prepend-icon"></v-icon>
      </template>
      <template #append v-if="booking.is_allow_cancel">
        <v-dialog max-width="500">
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-trash-can-outline"
              variant="outlined"
              color="error"
            ></v-btn>
          </template>
          <template v-slot:default="{ isActive }">
            <v-card title="Class Cancel Confirmation">
              <v-card-text>
                Please confirm that you really want to cancel this class?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text="Cancel Class"
                  @click="
                    isActive.value = false;
                    onCancelClass(booking);
                  "
                ></v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-list-item>
  </v-list>
  <p v-if="bookingList.length == 0" class="mt-5">No Upcoming Class</p>
</template>

<script setup>
import AppBar from "@/components/AppBar.vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { ref } from "vue";
import moment from "moment";

const route = useRoute();
const bookingList = ref([]);

loadUpcoming(route.params.memberId).then((response) => {
  bookingList.value = response.data;
});

function callClassCancel(bookingId) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_booking/id-${bookingId}/cancel`,
  });
}

function onCancelClass(booking) {
  callClassCancel(booking.booking_id).then(() => {
    loadUpcoming(route.params.memberId).then((response) => {
      bookingList.value = response.data;
    });
  });
}

function loadUpcoming(memberId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_booking/upcoming`,
    params: { member_id: memberId },
  });
}
</script>

<style scoped>
.list-prepend-icon {
  font-size: 2.4rem;
}
</style>
