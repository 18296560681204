<template>
  <div>
    <p class="booking-status">
      <v-icon :icon="icon(props.status)"></v-icon>
      {{ text(props.status) }}
    </p>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({ status: String });
const PROPS_DEFIND = {
  completed: { text: "Completed", icon: "mdi-check-circle", color: "#1B5E20" },
  rejected: { text: "Rejected", icon: "mdi-close", color: "#B71C1C" },
  canceled: { text: "Canceled", icon: "mdi-cancel", color: "#F57F17" },
  attend: {
    text: "Attend",
    icon: "mdi-map-marker-radius-outline",
    color: "#01579B",
  },
};

function icon(status) {
  if (status in PROPS_DEFIND) {
    return PROPS_DEFIND[status].icon;
  }
  return "";
}

function text(status) {
  if (status in PROPS_DEFIND) {
    return PROPS_DEFIND[status].text;
  }
  return "";
}

function color(status) {
  if (status in PROPS_DEFIND) {
    return PROPS_DEFIND[status].color;
  }
  return "#000000";
}
</script>

<style scoped>
.booking-status {
  font-size: 1.5rem;
  color: v-bind(color(props.status));
}
</style>
