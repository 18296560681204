import BaseThemeView from "@/views/admin_theme/BaseThemeView.vue";
import LoginView from "@/views/hq/LoginView.vue";
import DashboardView from "@/views/hq/DashboardView.vue";
import ShopListView from "@/views/hq/shop/ListView.vue";
import ShopDetailView from "@/views/hq/shop/DetailView.vue";
import AddUserShopView from "@/views/hq/shop/AddUserShopView.vue";
import UserListView from "@/views/hq/user/ListView.vue";
import sideBar from "@/components/hq/SideBar.vue";
import appBar from "@/components/hq/AppBar.vue";

export const hqLoginRouter = {
  path: "/hq/login",
  name: "hqLogin",
  component: LoginView,
};

export const hqRouter = {
  path: "/hq",
  component: BaseThemeView,
  children: [
    {
      path: "dashboard",
      name: "hqDashboard",
      components: { default: DashboardView, sideBar, appBar },
    },
    {
      path: "shop",
      children: [
        {
          path: "",
          name: "hqShopList",
          components: { default: ShopListView, sideBar, appBar },
        },
        {
          path: "id-:shopId",
          name: "hqShopDetail",
          components: { default: ShopDetailView, sideBar, appBar },
        },
        {
          path: "add-user-shop",
          name: "hqAddUserShop",
          components: { default: AddUserShopView, sideBar, appBar },
        },
      ],
    },
    {
      path: "user",
      name: "hqUserList",
      components: { default: UserListView, sideBar, appBar },
    },
  ],
};
