<template>
  <v-sheet class="text-start" v-if="shop">
    <page-head
      :title="shop.name"
      sub-head="settings"
      :breadcrumb="['Shop', 'Settings', shop.name]"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
      <v-tab value="appearance">Appearance</v-tab>
      <v-tab value="notify">Notify</v-tab>
      <v-tab value="connection">Connection</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input
              label="ID"
              :default="shop.shop_id"
              :readonly="true"
            ></patch-input>
            <patch-input
              label="Name"
              patch-key="name"
              :default="shop.name"
              :update-func="onUpdate"
            ></patch-input>
          </v-container>
        </v-tabs-window-item>
        <v-tabs-window-item value="appearance">
          <v-container fluid>
            <patch-image-file
              label="Logo (512 x 512 pixels)"
              patch-key="square_logo"
              :update-func="onUploadImage"
              :image-url="shop.square_logo_link"
            ></patch-image-file>
            <patch-color
              label="Primary Color"
              patch-key="primary_color"
              :default="shop.primary_color"
              :update-func="onUpdate"
            ></patch-color>
            <patch-color
              label="Secondary Color"
              patch-key="secondary_color"
              :default="shop.secondary_color"
              :update-func="onUpdate"
            ></patch-color>
            <patch-color
              label="Background Color"
              patch-key="background_color"
              :default="shop.background_color"
              :update-func="onUpdate"
            ></patch-color
          ></v-container>
        </v-tabs-window-item>
        <v-tabs-window-item value="notify">
          <h4 class="ma-3">Line Notify</h4>
          <patch-input
            label="Line Notify Token"
            patch-key="line_notify_token"
            :default="shop.line_notify_token"
            :update-func="onUpdate"
          ></patch-input>
          <h4 class="ma-3">Email Notify</h4>
          <patch-input
            label="Email"
            patch-key="email_notify"
            :default="shop.email_notify"
            :update-func="onUpdate"
          ></patch-input>
        </v-tabs-window-item>
        <v-tabs-window-item value="connection">
          <v-container fluid>
            <h3 class="ma-3">PaySolution</h3>
            <patch-input
              label="Merchant ID"
              patch-key="paysolution_merchant_id"
              :default="shop.paysolution_merchant_id"
              :update-func="onUpdate"
            ></patch-input>
            <patch-input
              label="API Key"
              patch-key="paysolution_api_key"
              :default="shop.paysolution_api_key"
              :update-func="onUpdate"
            ></patch-input>
            <patch-input
              label="Secret Key"
              patch-key="paysolution_secret_key"
              :default="shop.paysolution_secret_key"
              :update-func="onUpdate"
            ></patch-input>
            <h3 class="ma-3">Line</h3>
            <h4 class="ma-3">Liff ID: for customer login</h4>
            <patch-input
              label="Member Landing Page"
              patch-key="member_liff_id"
              :default="shop.member_liff_id"
              :update-func="onUpdate"
            ></patch-input>
            <h4 class="ma-3">LineOA Messaging API: Official Shop</h4>
            <patch-input
              label="Client ID"
              patch-key="line_messaging_client_id"
              :default="shop.line_messaging_client_id"
              :update-func="onUpdate"
            ></patch-input>
            <patch-input
              label="Client Secret"
              patch-key="line_messaging_client_secret"
              :default="shop.line_messaging_client_secret"
              :update-func="onUpdate"
            ></patch-input
          ></v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";
import PatchColor from "@/components/admin_theme/patch/PatchColor.vue";
import PatchImageFile from "@/components/admin_theme/patch/PatchImageFile.vue";

import { authAxios } from "@/util/Axios";
import { ref, inject } from "vue";

const axios = authAxios();
const cookies = inject("$cookies");

const tab = ref(null);
const shop = ref(null);
const shopId = cookies.get("toa-rim-shop");

console.log(cookies.keys());
loadShop(shopId).then((response) => {
  shop.value = { ...response.data };
});

function loadShop(shopId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/shop/id-${shopId}`,
  });
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/shop/${shopId}/`,
    data: { [key]: value },
  });
}

function onUploadImage(key, value) {
  var form_data = new FormData();
  form_data.append("upload_file", value);
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/shop/${shopId}/upload/${key}`,
    data: form_data,
  });
}
</script>
