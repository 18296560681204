import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import { VDateInput } from "vuetify/labs/VDateInput";
import { VTimePicker, VNumberInput } from "vuetify/lib/labs/components.mjs";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import VueQRCodeComponent from "vue-qrcode-component";
import VueCookies from "vue-cookies";
import VueGTag from "vue-gtag";

const app = createApp(App);

app.use(VueGTag, { config: { id: "G-ZMXX36LTP6" } });
app.use(store);
app.use(router);

const vuetify = createVuetify({
  components: {
    VDateInput,
    VTimePicker,
    VNumberInput,
    "qr-code": VueQRCodeComponent,
    ...components,
  },
  directives,
  theme: {
    defaultTheme: "light",
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});
app.use(vuetify);
app.use(VueCookies, { expires: "30d" });
app.mount("#app");
