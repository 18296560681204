<template>
  <v-col cols="12" class="d-flex align-center">
    <label>{{ props.label }}</label>
    <v-spacer></v-spacer>
    <input type="color" v-model="modelValue" />
  </v-col>
</template>

<script setup>
import { defineProps, toRef, watch } from "vue";
const props = defineProps(["label", "patchKey", "default", "updateFunc"]);
const modelValue = toRef(props.default);

watch(modelValue, (newValue) => {
  props.updateFunc(props.patchKey, newValue).then(() => {});
});
</script>

<style scoped>
label {
  font-size: 1.2rem;
}
</style>
