<template>
  <v-autocomplete
    v-if="member"
    v-model="selectedMember"
    prepend-inner-icon="mdi-account-box-outline"
    :rules="RULES.select"
    variant="outlined"
    label="Member"
    :items="member"
    item-value="id"
    item-title="first_name"
  >
    <template #item="{ props, item }">
      <v-list-item
        v-bind="props"
        :subtitle="`${item.raw.phone} - ${item.raw.email}`"
        :title="`${item.raw.first_name} ${item.raw.last_name}`"
      />
    </template>
  </v-autocomplete>
</template>

<script setup>
import { authAxios } from "@/util/Axios";
import { ref } from "vue";
import { RULES } from "@/util/Form";

const axios = authAxios();

const member = ref(null);
const selectedMember = ref(null);

loadMember().then((response) => {
  console.log(response);
  member.value = response.data;
});

function loadMember() {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/quick_select`,
  });
}
</script>
