import BaseEvent from "@/views/BaseEventView.vue";
import LineConnectView from "@/views/LineConnectView.vue";
import ClassInfoView from "@/views/classroom_event/ClassInfoView.vue";
import ClassSummaryView from "@/views/classroom_event/ClassSummaryView.vue";
import InvalidForBookingView from "@/views/InvalidForBookingView.vue";
import BackendConnector from "@/util/BackendConnector";

export const classEventRouter = {
  path: "/class-event/shop-:shopId",
  component: BaseEvent,
  children: [
    {
      path: "",
      name: "classEventLineConnect",
      component: LineConnectView,
      props: {
        loadData: (shopId) => BackendConnector.loadClassroomInitialData(shopId),
        liffId: (data) => data.classroom_liff_id,
        next: (route, memberId) => {
          return { name: "classInfo", query: { memberId: memberId } };
        },
      },
    },
    {
      path: "class-info",
      name: "classInfo",
      component: ClassInfoView,
    },
    {
      path: "booking-:bookingId",
      name: "classSummary",
      component: ClassSummaryView,
    },
    {
      path: "invalid-for-booking",
      name: "invalidForBooking",
      component: InvalidForBookingView,
    },
  ],
};
