import { authAxios } from "@/util/Axios.js";

export function useUserProfile() {
  const axios = authAxios();
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/auth/user/`,
  }).then((response) => {
    return {
      userEmail: response.data.email,
      currentShopName: response.data.current_shop_name,
      currentShopId: response.data.current_shop_id,
    };
  });
}
