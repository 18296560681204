<template>
  <v-sheet color="red-darken-4" class="footer">
    <v-row class="text-start ma-5 align-end">
      <v-col cols="12" md="6">
        <h3 class="mt-3">More from CodeGears</h3>
        <p><a href="https://www.codegears.co.th/">CodeGears Website</a></p>
        <h3 class="mt-3">Social</h3>
        <p>
          <a href="https://facebook.com/booking.engine.by.codegears/">
            Facebook
          </a>
        </p>
        <p>
          <a href="https://instagram.com/bookingenginebycodegears/">
            Instragram
          </a>
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <h3 class="mt-3">Site Access</h3>
        <p>
          <router-link :to="{ name: 'frontdeskLogin' }">
            Frontdesk Login
          </router-link>
        </p>
        <p>
          <router-link :to="{ name: 'managementLogin' }">
            Management Login
          </router-link>
        </p>
        <p class="mt-7">© 2024 Code Gears Co., Ltd. All rights reserved.</p>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<style scoped>
.footer a {
  color: white;
}
</style>
