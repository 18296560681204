<template>
  <fund-card
    v-if="props.funds.length == 1"
    :color="props.color"
    :fund="props.funds[0]"
  ></fund-card>
  <v-slide-group v-else class="fund-group">
    <v-slide-group-item v-for="f in props.funds" :key="f.id" class="fund-card">
      <fund-card :color="props.color" :fund="f"></fund-card>
    </v-slide-group-item>
  </v-slide-group>
</template>

<script setup>
import FundCard from "@/components/membership/FundCard.vue";
import { defineProps } from "vue";

const props = defineProps(["color", "funds"]);
</script>

<style scoped>
.fund-group > .fund-card {
  max-width: 300px;
}
</style>
