<template>
  <v-btn icon>
    <v-icon class="app-bar-icon">mdi-account-box-outline</v-icon>
    <v-menu activator="parent">
      <v-list min-width="250">
        <v-list-item>
          <v-list-item-title>
            <v-icon class="ms-5 me-3"> mdi-account-circle-outline </v-icon>
            <p class="list-text account-text">
              {{ props.userEmail }}
            </p></v-list-item-title
          >
        </v-list-item>
        <v-list-item
          v-if="props.shopSelectAllow"
          ref="storeBtn"
          value="1"
          color="red-darken-4"
          @click="isActive = True"
        >
          <v-list-item-title
            ><v-icon class="ms-5 me-3">mdi-store-outline</v-icon
            >{{ props.currentShop }}</v-list-item-title
          >
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item value="2" color="red-darken-4">
          <v-list-item-title @click="doLogout"
            ><v-icon class="ms-5 me-3">mdi-logout</v-icon
            >Logout</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>

  <v-dialog
    :activator="storeBtn"
    width="auto"
    min-width="320px"
    scrollable
    v-model="isActive"
  >
    <template v-slot:default>
      <v-card prepend-icon="mdi-store-outline" title="Select Shop">
        <v-divider class="mt-3"></v-divider>
        <v-card-text class="px-4" style="height: 400px">
          <v-radio-group v-model="selectedShop" column>
            <v-radio
              v-for="shop in shopList"
              :label="shop.name"
              :value="shop.id"
              :key="shop.id"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text="Close" @click="isActive = false"></v-btn>
          <v-spacer></v-spacer>
          <v-btn text="Save" @click="changeActiveShop"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup>
import { logout } from "@/util/Login";
import { authAxios } from "@/util/Axios";
import { defineProps, inject, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const cookies = inject("$cookies");
const props = defineProps([
  "userEmail",
  "currentShop",
  "shopSelectAllow",
  "logoutRedirect",
]);
const storeBtn = ref(null);
const selectedShop = ref(null);
const shopList = ref([]);
const isActive = ref(false);

loadShopList().then((response) => {
  for (const [key, value] of Object.entries(response.data)) {
    shopList.value.push({
      id: key,
      name: value,
    });
  }
  shopList.value.sort((a, b) => {
    if (a.name.toUpperCase() > b.name.toUpperCase()) {
      return 1;
    }
    return -1;
  });
});

function changeActiveShop() {
  cookies.set("toa-rim-shop", selectedShop.value);
  location.reload();
}

function loadShopList() {
  const axios = authAxios();
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/auth/shop_list`,
  });
}

function doLogout() {
  logout(props.logoutRedirect, cookies, router);
}
</script>

<style scoped>
.app-bar-icon {
  font-size: 2rem;
}

.list-text {
  display: inline;
}

.account-text {
  font-weight: 600;
}
</style>
