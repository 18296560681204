<template>
  <v-sheet class="text-start" v-if="topic && memberFund">
    <page-head
      :title="topic.name"
      sub-head="topics"
      :breadcrumb="['Class', 'Topics', topic.name]"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input
              label="ID"
              :default="topic.id"
              :readonly="true"
            ></patch-input>
            <patch-input
              label="Name"
              patch-key="name"
              :default="topic.name"
              :update-func="onUpdate"
            ></patch-input>
            <patch-input
              label="Period (min.)"
              patch-key="period"
              :default="topic.period"
              :update-func="onUpdate"
            ></patch-input>
            <patch-input
              label="Max Participant"
              patch-key="max_participant"
              :default="topic.max_participant"
              :update-func="onUpdate"
            ></patch-input>
            <patch-select
              label="Linked Member Fund"
              patch-key="linked_member_fund"
              :default="topic.linked_member_fund"
              :items="memberFund"
              :update-func="onUpdate"
            >
            </patch-select>
            <patch-select
              label="Color"
              patch-key="color"
              :default="topic.color"
              :items="COLOR"
              :update-func="onUpdate"
            >
            </patch-select>
            <patch-checkbox
              label="Private"
              patch-key="is_private"
              :default="topic.is_private"
              :update-func="onUpdate"
            ></patch-checkbox>
          </v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";
import PatchSelect from "@/components/admin_theme/patch/PatchSelect.vue";
import PatchCheckbox from "@/components/admin_theme/patch/PatchCheckbox.vue";

import BackendConnector from "@/util/BackendConnector";

import { authAxios } from "@/util/Axios";

import { ref } from "vue";
import { useRoute } from "vue-router";

const tab = ref(null);

const axios = authAxios();
const route = useRoute();

const topic = ref(null);
const memberFund = ref(null);

const COLOR = [
  "red",
  "pink",
  "purple",
  "deep-purple",
  "indigo",
  "blue",
  "light-blue",
  "cyan",
  "teal",
  "green",
  "light-green",
  "lime",
  "yellow",
  "amber",
  "orange",
  "deep-orange",
  "brown",
  "blue-grey",
];

BackendConnector.loadMemberFund().then((response) => {
  var result = [{ title: "No Link", value: "no_link" }];
  response.data.data.forEach((fund) => {
    result.push({ title: fund.name, value: fund.id });
  });
  memberFund.value = [...result];
});

loadTopic(route.params.topicId).then((response) => {
  topic.value = response.data;
});

function loadTopic(topicId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/topic/id-${topicId}`,
  });
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/topic/id-${route.params.topicId}`,
    data: { [key]: value },
  });
}
</script>
