<template>
  <v-card flat class="card">
    <div class="d-flex justify-end">
      <v-text-field
        class="ma-3"
        style="max-width: 300px"
        v-model="search"
        density="compact"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        flat
        hide-details
        single-line
      ></v-text-field>
    </div>
    <v-data-table-server
      :items="dataTable.items"
      :items-per-page="dataTable.itemsPerPage"
      :items-length="dataTable.totalItems"
      :headers="dataTable.headers"
      :loading="dataTable.isLoading"
      :items-per-page-options="dataTable.itemsPerPageOptions"
      @update:options="updateMember"
      @click:row="onRowClick"
    />
  </v-card>
  <v-bottom-sheet v-model="isMemberBottomSheetShow" inset>
    <member-sheet
      :member="selectedMember"
      @update-submit="onUpdateFundSubmit"
      :shop="props.shop"
    />
  </v-bottom-sheet>
</template>

<script setup>
import { defineProps, ref, reactive, watch } from "vue";
import MemberSheet from "@/components/frontdesk/MemberSheet.vue";
import { authAxios } from "@/util/Axios";
import { DEFAULT_DATATABLE } from "@/util/List";

const axios = authAxios();
const dataTable = reactive({
  headers: [
    { title: "Name", key: "name", align: "start", sortable: false },
    { title: "Phone", key: "phone", align: "start", sortable: false },
    { title: "Email", key: "email", align: "start", sortable: false },
  ],
  ...DEFAULT_DATATABLE,
});

const props = defineProps(["shop"]);
const isMemberBottomSheetShow = ref(false);
const selectedMember = ref("");
const search = ref("");

watch(search, () => {
  updateMember({ page: tablePage, itemsPerPage: tableItemPerPage });
});

function processMemberFund(fund) {
  let result = [];
  for (const [key, value] of Object.entries(fund)) {
    result.push({
      name: value.name,
      value: value.value,
      expire_date: value.expire_date,
      unlimited_access_end_date: value.unlimited_access_end_date,
      id: key,
    });
  }
  return result;
}

function onUpdateFundSubmit() {
  isMemberBottomSheetShow.value = false;
  updateMember({ page: tablePage, itemsPerPage: tableItemPerPage });
}

var tablePage = 0;
var tableItemPerPage = 20;

function updateMember({ page, itemsPerPage }) {
  tablePage = page;
  tableItemPerPage = itemsPerPage;
  dataTable.isLoading = true;
  loadMember(page, itemsPerPage, search.value).then((response) => {
    dataTable.isLoading = false;
    var items = [];
    response.data.data.forEach((member) => {
      items.push({
        id: member.id,
        name: member.first_name + " " + member.last_name,
        phone: member.phone,
        email: member.email,
        fund: processMemberFund(member.fund),
        wallet_amount: member.wallet_amount,
      });
    });
    dataTable.items = items;
    dataTable.totalItems = response.data.total_items;
  });
}

function loadMember(page, length, search) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/vuetify`,
    params: { page: page, length: length, search: search },
  });
}

function onRowClick(event, row) {
  isMemberBottomSheetShow.value = true;
  selectedMember.value = row.item;
}
</script>

<style scoped>
.card {
  text-align: start;
}
</style>

<!--
  ToDo:
    1.) Show FundValue in memberBottomSheet
    2.) click FundValue for update the value
-->
