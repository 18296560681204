<template>
  <div class="text-start d-flex align-center py-3">
    <div class="flex-grow-1">
      <label>{{ props.label }}</label>
      <p class="value">{{ props.defaultValue }}</p>
    </div>
    <v-icon v-if="allowEdit" icon="mdi-pencil" @click="dialog = true"></v-icon>
  </div>
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-sheet class="pa-3 text-center">
      <h2>{{ props.label }}</h2>
      <slot name="editor" :update-value="updateValueFunc"> </slot>
      <div class="d-flex">
        <v-btn class="me-auto" @click="dialog = False">Cancel</v-btn
        ><v-btn
          @click="
            dialog = False;
            emits('fieldSubmit', inputValue);
          "
          >Ok</v-btn
        >
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
const props = defineProps(["label", "defaultValue", "allowEdit"]);
const emits = defineEmits(["fieldSubmit"]);
const dialog = ref(false);
const inputValue = ref("");

function updateValueFunc(value) {
  inputValue.value = value;
}
</script>

<style scoped>
label {
  font-weight: 600;
}

.value {
  font-size: 1.2rem;
}
</style>

<!--
  ToDo: Define slot for display when edit-icon clicked
  ToDo: emits update with updated value return from display
-->
