<template>
  <app-bar title="Wallet History" :member-id="$route.params.memberId" />
  <v-tabs v-model="tab" :fixed-tabs="true">
    <v-tab value="topUp">Top-Up</v-tab>
    <v-tab value="adjusted">Adjusted</v-tab>
  </v-tabs>
  <v-tabs-window v-model="tab">
    <v-tabs-window-item value="topUp">
      <v-list class="bg-transparent text-start" lines="two">
        <v-list-item
          v-for="topUp in topUpList"
          :key="topUp.reference_no"
          :title="`${topUp.price} THB`"
          :subtitle="moment(topUp.create_local_dt).format('MMM DD, YYYY HH:mm')"
        >
          <template #prepend>
            <v-icon
              icon="mdi-credit-card-outline"
              class="list-prepend-icon"
            ></v-icon>
          </template>
        </v-list-item>
      </v-list>
      <p v-if="topUpList.length == 0" class="mt-5">No Top-Up History</p>
    </v-tabs-window-item>
    <v-tabs-window-item value="adjusted">
      <v-list class="bg-transparent text-start" lines="two">
        <v-list-item
          v-for="adjusted in adjustedList"
          :key="adjusted.id"
          :title="`${adjusted.changing_value} THB`"
          :subtitle="
            moment(adjusted.create_local_dt).format('MMM DD, YYYY HH:mm')
          "
        >
          <template #prepend>
            <v-icon icon="mdi-update" class="list-prepend-icon"></v-icon>
          </template>
        </v-list-item>
      </v-list>
      <p v-if="adjustedList.length == 0" class="mt-5">No Adjusted Log</p>
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script setup>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import BackendConnector from "@/util/BackendConnector";
import { ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const tab = ref(null);
const topUpList = ref([]);
const adjustedList = ref([]);

BackendConnector.loadTopUpHistory(route.params.memberId).then((response) => {
  topUpList.value = response.data;
});

BackendConnector.loadWalletAdjustLog(route.params.memberId).then((response) => {
  adjustedList.value = response.data;
});
</script>
