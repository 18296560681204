import BaseThemeView from "@/views/admin_theme/BaseThemeView.vue";
import LoginView from "@/views/frontdesk/LoginView.vue";
import DashboardView from "@/views/frontdesk/DashboardView.vue";

import ClassroomWelcomeView from "@/views/frontdesk/classroom/WelcomeView.vue";
import ClassroomDetailView from "@/views/frontdesk/classroom/DetailView.vue";

import FacilityWelcomeView from "@/views/frontdesk/facility/WelcomeView.vue";

import MembershipView from "@/views/frontdesk/membership/MembershipView.vue";
import MemberDetailView from "@/views/frontdesk/membership/DetailView.vue";

import sideBar from "@/components/frontdesk/SideBar.vue";
import appBar from "@/components/frontdesk/AppBar.vue";

export const frontdeskLoginRouter = {
  path: "/frontdesk/login",
  name: "frontdeskLogin",
  component: LoginView,
};

export const frontdeskRouter = {
  path: "/frontdesk",
  component: BaseThemeView,
  children: [
    {
      path: "dashboard",
      name: "frontdeskDashboard",
      components: { default: DashboardView, sideBar, appBar },
    },
    {
      path: "classroom",
      children: [
        {
          path: "welcome",
          name: "frontdeskClassroomWelcome",
          components: { default: ClassroomWelcomeView, sideBar, appBar },
        },
        {
          path: "id-:classId",
          name: "frontdeskClassroomDetail",
          components: { default: ClassroomDetailView, sideBar, appBar },
        },
      ],
    },
    {
      path: "facility",
      children: [
        {
          path: "welcome",
          name: "frontdeskFacilityWelcome",
          components: { default: FacilityWelcomeView, sideBar, appBar },
        },
      ],
    },
    {
      path: "membership",
      children: [
        {
          path: "",
          name: "frontdeskMembership",
          components: { default: MembershipView, sideBar, appBar },
        },
        {
          path: "member-:memberId",
          name: "frontdeskMemberDetail",
          components: { default: MemberDetailView, sideBar, appBar },
        },
      ],
    },
  ],
};
