<template>
  <v-layout class="rounded rounded-md" v-if="shopDetail">
    <router-view
      name="sideBar"
      v-model="isDrawerVisibleOnMobile"
      :shop="shopDetail"
    />
    <router-view name="appBar" @drawer-icon-clicked="onDrawerShow" />
    <v-main style="min-height: 300px">
      <router-view :shop="shopDetail" />
    </v-main>
  </v-layout>
</template>

<script setup>
import { ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useUserProfile } from "@/util/UserProfile.js";
import BackendConnector from "@/util/BackendConnector";

const shopDetail = ref(null);

const display = useDisplay();
const isDrawerVisibleOnMobile = ref(!display.mdAndDown.value);

function onDrawerShow() {
  isDrawerVisibleOnMobile.value = true;
}

const userProfile = ref({
  userEmail: "xxyy@zzzaa.com",
  currentShopName: "My Shop",
  currentShopId: "my_shop",
});

useUserProfile().then((response) => {
  userProfile.value = response;
  BackendConnector.loadShopDetail(userProfile.value.currentShopId).then((r) => {
    shopDetail.value = r.data;
  });
});
</script>

<style scoped>
.app-bar {
  text-align: left;
}
</style>
