<template>
  <div class="d-flex powered-by-code-gears justify-center align-center">
    <v-img
      :width="24"
      :max-width="24"
      cover
      :src="require('@/assets/img/logo_booking_white.svg')"
    />
    <p class="ml-3">Powered by <strong>CodeGears</strong></p>
  </div>
</template>

<style scoped>
.powered-by-code-gears {
  color: white;
  filter: brightness(0.1);
  font-size: 0.8rem;
}
</style>
