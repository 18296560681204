<template>
  <v-app-bar
    :title="userProfile.currentShopName"
    class="app-bar rounded-lg bg-red-darken-4"
  >
    <template #prepend>
      <v-app-bar-nav-icon
        v-if="$vuetify.display.mdAndDown"
        @click="emits('drawerIconClicked')"
      />
    </template>
    <user-menu-icon
      :user-email="userProfile.userEmail"
      :current-shop="userProfile.currentShopName"
      :shop-select-allow="true"
      :logout-redirect="{ name: 'managementLogin' }"
    />
  </v-app-bar>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import UserMenuIcon from "@/components/admin_theme/UserMenu.vue";
import { useUserProfile } from "@/util/UserProfile.js";

const emits = defineEmits(["drawerIconClicked"]);

const userProfile = ref({
  userEmail: "xxyy@zzzaa.com",
  currentShopName: "My Shop",
  currentShopId: "my_shop",
});

useUserProfile().then((response) => {
  userProfile.value = response;
});
</script>
