<template>
  <app-bar title="Classroom Form" :member-id="$route.query.memberId" />

  <v-overlay :model-value="isLoading" class="align-center justify-center">
    <v-progress-circular color="white" size="64" indeterminate />
  </v-overlay>

  <div class="booking-card d-flex flex-column">
    <v-select
      v-if="branchs.length > 1"
      v-model="selectedBranch"
      label="Branch"
      :items="branchs"
      variant="solo"
      prepend-inner-icon="mdi-map-marker"
    />
    <transition name="fade">
      <v-date-input
        v-if="stepReach('BRANCH_SELECTED')"
        v-model="selectedDate"
        label="Date"
        variant="solo"
        :hide-actions="true"
        prepend-icon=""
        prepend-inner-icon="$calendar"
        :allowed-dates="allowedDates"
      />
    </transition>
    <transition name="left-slide">
      <div v-if="stepReach('DATE_SELECTED')" class="class-picker-container">
        <class-picker
          v-for="c in classData"
          :key="c.id"
          :class-data="c"
          :member-id="$route.query.memberId"
          :class="{ active: c.id == selectedClass }"
          @picked="classPicked"
        />
        <div v-if="!classData.length">
          <p>No class for this day.</p>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <v-btn
        v-if="stepReach('CLASS_SELECTED')"
        class="booking-submit-btn"
        block
        size="large"
        :color="props.ui.secondaryColor"
        @click="submitClassInfo"
      >
        Book
      </v-btn>
    </transition>
  </div>
</template>

<script setup>
import AppBar from "@/components/AppBar.vue";
import { ref, reactive, watch, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";
import ClassPicker from "@/components/ClassPicker.vue";
import BackendConnector from "@/util/BackendConnector.js";
import axios from "axios";
import moment from "moment";

const props = defineProps(["ui"]);
const route = useRoute();
const router = useRouter();

const branchs = reactive([]);
var allowedDates = reactive([]);
var classData = reactive([]);

const selectedBranch = ref(null);
const selectedDate = ref(null);
const selectedClass = ref("");
const isLoading = ref(false);
const memberId = route.query.memberId;

const STEP = [
  "NOTHING_SELECTED",
  "BRANCH_SELECTED",
  "DATE_SELECTED",
  "CLASS_SELECTED",
];
const currentStep = ref(0);

isLoading.value = true;
BackendConnector.loadBranchs(route.params.shopId).then((response) => {
  console.log("Response: ", response);
  Object.assign(branchs, [...response]);
  if (branchs.length == 1) {
    selectedBranch.value = response[0].value;
  }
  isLoading.value = false;
});

watch(selectedBranch, (newValue) => {
  console.log(newValue);
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  isLoading.value = true;
  loadDates(newValue).then(() => {
    isLoading.value = false;
    currentStep.value = STEP.indexOf("BRANCH_SELECTED");
  });
});

watch(selectedDate, (newValue) => {
  currentStep.value = STEP.indexOf("BRANCH_SELECTED");
  isLoading.value = true;
  loadClassrooms(selectedBranch.value, newValue).then(() => {
    isLoading.value = false;
    currentStep.value = STEP.indexOf("DATE_SELECTED");
  });
});

async function loadClassrooms(branchId, date) {
  return axios
    .get(`${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/classrooms`, {
      params: { branch_id: branchId, date: moment(date).format("YYYY-MM-DD") },
    })
    .then((response) => {
      var result = [];
      response.data.forEach((eachClass) => {
        if (!eachClass.is_private) {
          result.push(eachClass);
        }
      });
      classData = [...result];
    });
}

async function loadDates(branchId) {
  return axios
    .get(`${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/dates`, {
      params: { branch_id: branchId },
    })
    .then((response) => {
      allowedDates = [...response.data];
    });
}

function classPicked(classId) {
  selectedClass.value = classId;
  currentStep.value = STEP.indexOf("CLASS_SELECTED");
}

function submitClassInfo() {
  isLoading.value = true;
  BackendConnector.makeClassBooking(selectedClass.value, memberId)
    .then((response) => {
      if (response) {
        router.push({
          name: "classSummary",
          params: { bookingId: response.data.booking_id },
        });
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
}

function stepReach(step) {
  return currentStep.value >= STEP.indexOf(step);
}
</script>

<style scoped>
.booking-card {
  margin: 2rem 1rem;
}

.booking-submit-btn {
  margin-top: 2rem !important;
}
</style>
