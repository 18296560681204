import axios from "axios";
import moment from "moment";

export async function useProfile(memberId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}`,
  }).then((response) => {
    var result = {
      firstName: response.data.first_name,
      lastName: response.data.last_name,
      fullName: `${response.data.first_name} ${response.data.last_name}`,
      birthdate: moment(response.data.birthdate).toDate(),
      pictureUrl: response.data.line_picture_url,
      displayName: response.data.line_display_name,
      phone: response.data.phone,
      email: response.data.email,
      funds: [],
      walletAmount: response.data.wallet_amount,
    };
    for (const [k, v] of Object.entries(response.data.fund)) {
      result.funds.push({
        id: k,
        name: v.name,
        value: v.value,
        expireDate: v.expire_date,
        unlimitedAccessEndDate: v.unlimited_access_end_date,
        isUnlimitedAccess: !moment(v.unlimited_access_end_date).isBefore(
          new Date(),
          "day"
        ),
      });
    }
    return result;
  });
}

export async function updateProfile(memberId, profile) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}`,
    data: profile,
  }).then((response) => {
    console.log(response);
  });
}
