<template>
  <v-file-input
    variant="outlined"
    prepend-icon=""
    prepend-inner-icon="mdi-image"
    :loading="isLoading"
    v-model="modelValue"
  >
    <template #append>
      <v-img
        width="56"
        :src="`${props.imageUrl}?a=${randomNum}`"
        rounded="lg"
      ></v-img></template
  ></v-file-input>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
const props = defineProps(["patchKey", "updateFunc", "imageUrl"]);
const modelValue = ref(null);
const isLoading = ref(false);
const randomNum = ref(Math.random());
watch(modelValue, (newValue) => {
  props.updateFunc(props.patchKey, newValue).then(() => {
    randomNum.value = Math.random();
  });
});
</script>
