<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
      v-if="props.shop.feature_enable.class_event"
    >
      <dashboard-quick-link
        title="งานต้อนรับนักเรียน"
        description="สำหรับต้อนรับลูกค้า Classroom"
        :to="{ name: 'frontdeskClassroomWelcome' }"
        icon="mdi-human-male-board"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
      v-if="props.shop.feature_enable.facility_event"
    >
      <dashboard-quick-link
        title="งานต้อนรับใช้สนาม"
        description="สำหรับต้อนรับลูกค้า Facility"
        :to="{ name: 'frontdeskFacilityWelcome' }"
        icon="mdi-land-plots"
      />
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="4">
      <dashboard-quick-link
        title="งานด้านสมาชิก"
        description="ให้บริการสมาชิกปัจจุบัน"
        :to="{ name: 'frontdeskMembership' }"
        icon="mdi-wallet-membership"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import DashboardQuickLink from "@/components/frontdesk/DashboardQuickLink.vue";
import { defineProps } from "vue";

const props = defineProps(["shop"]);
</script>
