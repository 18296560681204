import { createRouter, createWebHistory } from "vue-router";

import { webRouter } from "./Web.js";
import { membershipLineConnectRouter, membershipRouter } from "./Membership.js";
import { classEventRouter } from "./ClassEvent.js";
import { facilityEventRouter } from "./FacilityEvent.js";
import { frontdeskLoginRouter, frontdeskRouter } from "./Frontdesk.js";
import { hqLoginRouter, hqRouter } from "./HQ.js";
import { managementLoginRouter, managementRouter } from "./Management.js";

const routes = [
  webRouter,
  membershipLineConnectRouter,
  membershipRouter,
  classEventRouter,
  facilityEventRouter,
  frontdeskLoginRouter,
  frontdeskRouter,
  hqLoginRouter,
  hqRouter,
  managementLoginRouter,
  managementRouter,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
