<template>
  <v-select variant="outlined" v-model="modelValue"></v-select>
</template>

<script setup>
import { defineProps, toRef, watch } from "vue";
const props = defineProps(["default", "patchKey", "readonly", "updateFunc"]);
const modelValue = toRef(props.default);

watch(modelValue, (newValue) => {
  props.updateFunc(props.patchKey, newValue).then(() => {});
});
</script>
