<template>
  <app-bar title="Setting" :member-id="$route.params.memberId" />
  <h2 class="text-start mx-5 mt-5">Profile</h2>
  <v-sheet class="ma-5 pa-5" rounded="xl" elevation="2">
    <mobile-field
      label="First Name"
      :allow-edit="true"
      :default-value="profile.firstName"
      @field-submit="onFirstNameSubmit"
    >
      <template #editor="{ updateValue }">
        <v-text-field
          variant="outlined"
          :model-value="profile.firstName"
          @update:model-value="updateValue"
        ></v-text-field>
      </template>
    </mobile-field>
    <mobile-field
      label="Last Name"
      :allow-edit="true"
      :default-value="profile.lastName"
      @field-submit="onLastNameSubmit"
    >
      <template #editor="{ updateValue }">
        <v-text-field
          variant="outlined"
          :model-value="profile.lastName"
          @update:model-value="updateValue"
        ></v-text-field>
      </template>
    </mobile-field>
    <mobile-field
      label="Birthdate"
      :allow-edit="true"
      :default-value="displayBirthdate(profile.birthdate)"
      @field-submit="onBirthdateSubmit"
    >
      <template #editor="{ updateValue }">
        <v-date-picker
          width="100%"
          title=""
          v-model="profile.birthdate"
          @update:modelValue="updateValue"
        ></v-date-picker>
      </template>
    </mobile-field>
  </v-sheet>
  <h2 class="text-start mx-5 mt-5">Contact</h2>
  <v-sheet class="ma-5 pa-5" rounded="xl" elevation="2">
    <mobile-field
      label="Phone Number"
      :allow-edit="true"
      :default-value="profile.phone"
      @field-submit="onPhoneSubmit"
    >
      <template #editor="{ updateValue }">
        <v-text-field
          variant="outlined"
          :model-value="profile.phone"
          @update:model-value="updateValue"
        ></v-text-field>
      </template>
    </mobile-field>
    <mobile-field
      label="Email Address"
      :allow-edit="true"
      :default-value="profile.email"
      @field-submit="onEmailSubmit"
    >
      <template #editor="{ updateValue }">
        <v-text-field
          variant="outlined"
          :model-value="profile.email"
          @update:model-value="updateValue"
        ></v-text-field>
      </template>
    </mobile-field>
  </v-sheet>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

import AppBar from "@/components/AppBar.vue";
import MobileField from "@/components/membership/MobileField.vue";
import { useProfile } from "@/util/MemberProfile";
import axios from "axios";
import moment from "moment";

const route = useRoute();
const profile = ref({});
useProfile(route.params.memberId).then((response) => {
  profile.value = response;
});

function onFirstNameSubmit(value) {
  onUpdate("first_name", value);
  profile.value.firstName = value;
}

function onLastNameSubmit(value) {
  onUpdate("last_name", value);
  profile.value.lastName = value;
}

function onBirthdateSubmit(value) {
  onUpdate("birthdate", moment(value).format("YYYY-MM-DD"));
}

function onPhoneSubmit(value) {
  onUpdate("phone", value);
  profile.value.phone = value;
}

function onEmailSubmit(value) {
  onUpdate("email", value);
  profile.value.email = value;
}

function displayBirthdate(date) {
  const d = moment(date).format("MMM DD, YYYY");
  if (d == "Invalid date") {
    return "-";
  }
  return d;
}

function onUpdate(fieldName, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${route.params.memberId}`,
    data: { [fieldName]: value },
  });
}
</script>
