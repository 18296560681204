<template>
  <v-sheet class="text-start" v-if="shop">
    <page-head
      sub-head="Shops"
      :title="shop.name"
      :breadcrumb="['Shops', shop.name]"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
      <v-tab value="feature">Feature</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-date
              label="Next Billing Date"
              :default="shop.next_billing_date"
              patch-key="next_billing_date"
              :updateFunc="onUpdate"
            ></patch-date>
          </v-container>
        </v-tabs-window-item>
        <v-tabs-window-item value="feature">
          <patch-checkbox
            label="Class Event"
            patch-key="class_event"
            :default="shop.feature_enable.class_event"
            :update-func="onFeatureUpdate"
          ></patch-checkbox>
          <patch-checkbox
            label="Facility Event"
            patch-key="facility_event"
            :default="shop.feature_enable.facility_event"
            :update-func="onFeatureUpdate"
          ></patch-checkbox>
          <patch-checkbox
            label="Member Wallet"
            patch-key="member_wallet"
            :default="shop.feature_enable.member_wallet"
            :update-func="onFeatureUpdate"
          ></patch-checkbox>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import { authAxios } from "@/util/Axios";
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchDate from "@/components/admin_theme/patch/PatchDate.vue";
import PatchCheckbox from "@/components/admin_theme/patch/PatchCheckbox.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import moment from "moment";

const axios = authAxios();
const route = useRoute();
const tab = ref(null);
const shop = ref(null);

loadShop(route.params.shopId).then((response) => {
  shop.value = response.data;
  shop.value.next_billing_date = moment(
    response.data.next_billing_date
  ).toDate();
});

function loadShop(shopId) {
  return axios({
    methods: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/shop/id-${shopId}`,
  });
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/shop/${route.params.shopId}`,
    data: { [key]: value },
  });
}

function onFeatureUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/shop/id-${route.params.shopId}/feature_enable`,
    data: { [key]: value },
  });
}
</script>
