import BaseWeb from "@/views/web/BaseWeb.vue";

import WebHome from "@/views/web/WebHome.vue";
import WebFeature from "@/views/web/WebFeature.vue";
import WebContact from "@/views/web/WebContact.vue";
import LandingBeauty from "@/views/web/landing/LandingBeauty.vue";
import LandingRestaurant from "@/views/web/landing/LandingRestaurant.vue";
import LandingYoga from "@/views/web/landing/LandingYoga.vue";

export const webRouter = {
  path: "",
  component: BaseWeb,
  children: [
    {
      path: "home",
      name: "webHome",
      component: WebHome,
    },
    {
      path: "feature",
      name: "webFeature",
      component: WebFeature,
    },
    {
      path: "contact",
      name: "webContact",
      component: WebContact,
    },
    {
      path: "landing",
      children: [
        {
          path: "beauty",
          name: "webLandingBeauty",
          component: LandingBeauty,
        },
        {
          path: "restaurant",
          name: "webLandingRestaurant",
          component: LandingRestaurant,
        },
        {
          path: "yoga",
          name: "webLandingYoga",
          component: LandingYoga,
        },
      ],
    },
  ],
};
