<template>
  <v-sheet class="text-start" v-if="resource && branchs">
    <page-head
      :title="resource.name"
      sub-head="resources"
      :breadcrumb="['Facility', 'Resources', resource.name]"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
      <v-tab value="timeslot">Timeslot</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input
              label="ID"
              :default="resource.id"
              :readonly="true"
            ></patch-input>
            <patch-input
              label="Name"
              patch-key="name"
              :default="resource.name"
              :update-func="onUpdate"
            ></patch-input>
            <patch-select
              label="Branch"
              patch-key="branch_id"
              :default="resource.branch_id"
              :items="branchs"
              :update-func="onUpdate"
            ></patch-select>
            <patch-input
              label="Amount"
              patch-key="amount"
              :default="resource.amount"
              :update-func="onUpdate"
            ></patch-input>
          </v-container>
        </v-tabs-window-item>
        <v-tabs-window-item value="timeslot">
          <div class="d-flex flex-column">
            <v-dialog max-width="500">
              <template #activator="{ props: activatorProps }">
                <v-btn v-bind="activatorProps" max-width="200" class="ms-auto">
                  Create Timeslot
                </v-btn>
              </template>
              <template #default="{ isActive }">
                <v-card
                  class="pa-5"
                  prepend-icon="mdi-note-plus-outline"
                  title="Create Timeslot"
                >
                  <v-sheet class="d-flex flex-column">
                    <v-select
                      label="Weekday"
                      :items="PYTHON_WEEKDAY"
                      variant="outlined"
                      v-model="weekday"
                    ></v-select>
                    <v-select
                      label="Start Time"
                      :items="TIME"
                      variant="outlined"
                      v-model="startTime"
                    ></v-select>
                    <v-select
                      label="End Time"
                      :items="TIME"
                      variant="outlined"
                      v-model="endTime"
                    ></v-select>
                    <v-text-field
                      label="Price (THB)"
                      variant="outlined"
                      v-model="price"
                    ></v-text-field>
                  </v-sheet>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn @click="isActive.value = false">Cancel</v-btn>
                    <v-btn
                      @click="
                        isActive.value = false;
                        createTimeslot();
                      "
                      >Create
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <v-data-table-server
              :items="dataTable.items"
              :items-per-page="-1"
              :items-length="dataTable.totalItems"
              :headers="dataTable.headers"
              :loading="dataTable.isLoading"
            >
              <template v-slot:[`item.weekday`]="{ value }">
                {{ moment(pythonToJsWeekday(value), "d").format("dddd") }}
              </template>
              <template v-slot:[`item.start_time`]="{ value }">
                {{ moment(value, "HH:mm:ss").format("HH:mm") }}
              </template>
              <template v-slot:[`item.end_time`]="{ value }">
                {{ moment(value, "HH:mm:ss").format("HH:mm") }}
              </template>
            </v-data-table-server>
          </div>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";
import PatchSelect from "@/components/admin_theme/patch/PatchSelect.vue";
import BackendConnector from "@/util/BackendConnector";

import { authAxios } from "@/util/Axios";
import { reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useUserProfile } from "@/util/UserProfile";
import { DEFAULT_DATATABLE } from "@/util/List";
import moment from "moment";

const PYTHON_WEEKDAY = [
  { title: "Monday", value: 0 },
  { title: "Tuesday", value: 1 },
  { title: "Wednesday", value: 2 },
  { title: "Thursday", value: 3 },
  { title: "Friday", value: 4 },
  { title: "Saturday", value: 5 },
  { title: "Sunday", value: 6 },
];

const TIME = [];
for (let i = 0; i < 24; i++) {
  TIME.push(moment(i, "H").format("HH:mm"));
}

const tab = ref(null);

const axios = authAxios();
const route = useRoute();

const resource = ref(null);

const branchs = ref(null);

const dataTable = reactive({
  headers: [
    { title: "Weekday", key: "weekday", align: "start", sortable: false },
    { title: "Start Time", key: "start_time", sortable: false },
    { title: "End Time", key: "end_time", sortable: false },
    { title: "Price (THB)", key: "price", sortable: false },
  ],
  ...DEFAULT_DATATABLE,
});

const weekday = ref(null);
const startTime = ref(null);
const endTime = ref(null);
const price = ref(null);

loadResource(route.params.resourceId).then((response) => {
  resource.value = response.data;
  dataTable.items = [...response.data.slot];
});

const profile = reactive({});
useUserProfile().then((response) => {
  Object.assign(profile, response);
  BackendConnector.loadBranchs(profile.currentShopId).then((response) => {
    branchs.value = [...response];
  });
});

function createTimeslot() {
  console.log(weekday.value, startTime.value, endTime.value, price.value);
  makeTimeslot(
    route.params.resourceId,
    weekday.value,
    startTime.value,
    endTime.value,
    price.value
  ).then(() => {
    loadResource(route.params.resourceId).then((response) => {
      resource.value = response.data;
      dataTable.items = [...response.data.slot];
      weekday.value = null;
      startTime.value = null;
      endTime.value = null;
      price.value = null;
    });
  });
}

function makeTimeslot(resourceId, weekday, startTime, endTime, price) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/resource/id-${resourceId}/create_timeslot`,
    data: { weekday, start_time: startTime, end_time: endTime, price: price },
  });
}

function pythonToJsWeekday(weekday) {
  return (weekday + 1) % 7;
}

function loadResource(resourceId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/resource/id-${resourceId}`,
  });
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/resource/id-${route.params.resourceId}`,
    data: { [key]: value },
  });
}
</script>
