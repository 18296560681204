import BackendConnector from "@/util/BackendConnector";
import BaseEvent from "@/views/BaseEventView.vue";
import LineConnectView from "@/views/LineConnectView.vue";
import FacilityInfoView from "@/views/facility_event/FacilityInfoView.vue";
import FacilitySummaryView from "@/views/facility_event/FacilitySummaryView.vue";

export const facilityEventRouter = {
  path: "/facility-event/shop-:shopId",
  component: BaseEvent,
  children: [
    {
      path: "",
      name: "facilityEventLineConnect",
      component: LineConnectView,
      props: {
        loadData: (shopId) => BackendConnector.loadFacilityConfig(shopId),
        liffId: (data) => data.data.liff_id,
        next: (route, memberId) => {
          return { name: "facilityInfo", query: { memberId: memberId } };
        },
      },
    },
    {
      path: "facility-info",
      name: "facilityInfo",
      component: FacilityInfoView,
    },
    {
      path: "booking-:bookingId",
      name: "facilitySummary",
      component: FacilitySummaryView,
    },
  ],
};
