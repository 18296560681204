<template>
  <form-container
    :left-background="`url(${leftBackgroundUrl})`"
    right-background-color="#b7ebff"
  >
    <template #leftHeader>
      <h1 class="left-h ma-10">
        เพื่อ
        <span class="left-h-highlight">งานบริหารจัดการ</span>
        ที่ง่ายกว่าเดิมของธุรกิจคุณ
      </h1>
    </template>
    <template #default>
      <h2 class="right-h my-10 mx-5">
        Welcome to <span class="right-h-highlight">Management</span>
      </h2>
      <v-form class="ma-0 text-start" @submit.prevent="loginSubmit">
        <v-text-field
          v-model="email"
          label="Email"
          prepend-inner-icon="mdi-email"
          variant="outlined"
          :rules="emailRule"
          class="mb-3"
        />
        <v-text-field
          v-model="password"
          label="Password"
          type="password"
          prepend-inner-icon="mdi-lock"
          variant="outlined"
        />
        <p class="text-center mb-10">
          <a :href="forgetPasswordLink">forget your password</a>
        </p>
        <v-btn
          type="submit"
          block
          class="mb-5"
          variant="flat"
          color="red-darken-3"
          size="large"
        >
          Login
        </v-btn>
      </v-form>
      <v-divider class="py-3"></v-divider>
      <ul class="text-start ms-5">
        <li><a :href="frontdeskLink">Login to Frontdesk</a></li>
      </ul>
    </template>
  </form-container>
</template>

<script setup>
import BackendConnector from "@/util/BackendConnector";
import { checkForLoginAndRedirect } from "@/util/Login";
import { defineModel, inject } from "vue";
import { useRouter } from "vue-router";
import FormContainer from "@/components/auth_form/FormContainer.vue";

const emailRule = [(v) => /.+@.+/.test(v) || "Invalid Email address"];

const email = defineModel("email");
const password = defineModel("password");

const cookies = inject("$cookies");
const router = useRouter();

const leftBackgroundUrl = require("@/assets/img/login/admin_left_col.png");
const forgetPasswordLink = `${process.env.VUE_APP_BACKEND_URL}/shop/forget_password`;
const frontdeskLink = `${process.env.VUE_APP_BASE_URL}/frontdesk/login`;

checkForLoginAndRedirect({ name: "managementDashboard" });

function loginSubmit() {
  BackendConnector.doLogin(email.value, password.value)
    .then((response) => {
      cookies.set("access-token", response.data.access_token);
      cookies.set("toa-rim-shop", response.data.shop_id);
      router.push({ name: "managementDashboard" });
    })
    .catch((error) => {
      alert(error.response.data.detail);
    });
}
</script>

<style scoped>
.left-h {
  font-size: 2.4rem;
  font-weight: 400;
}

.left-h-highlight {
  color: #d71f26;
}

.right-h {
  font-weight: 400;
  font-size: 2rem;
}

.right-h-highlight {
  color: #00a4e5;
}
</style>
