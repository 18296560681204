<template>
  <v-sheet class="text-start">
    <page-head title="Resource" :breadcrumb="['Facility', 'Resource']">
      <template v-slot>
        <v-btn
          variant="outlined"
          color="primary"
          :to="{ name: 'resourceCreate' }"
          ><v-icon>mdi-plus</v-icon>Create Resource</v-btn
        >
      </template>
    </page-head>
    <v-data-table-server
      :items="dataTable.items"
      :items-per-page="dataTable.itemsPerPage"
      :items-length="dataTable.totalItems"
      :headers="dataTable.headers"
      :loading="dataTable.isLoading"
      :items-per-page-options="dataTable.itemsPerPageOptions"
      @update:options="updateResource"
    >
      <template v-slot:[`item.name`]="{ value, item }">
        <a :href="`/management/resource/id-${item.id}`">
          {{ value }}
        </a></template
      ></v-data-table-server
    >
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { reactive } from "vue";
import { authAxios } from "@/util/Axios";
import { DEFAULT_DATATABLE } from "@/util/List";

const axios = authAxios();
const dataTable = reactive({
  headers: [
    { title: "Name", key: "name", align: "start", sortable: false },
    { title: "Branch", key: "branch_name", sortable: false },
    { title: "Amount", key: "amount", sortable: false },
  ],
  ...DEFAULT_DATATABLE,
});

function updateResource({ page, itemsPerPage }) {
  dataTable.isLoading = true;
  loadResource(page, itemsPerPage).then((response) => {
    dataTable.isLoading = false;
    dataTable.items = [...response.data.data];
    dataTable.totalItems = response.data.total_items;
  });
}

function loadResource(page, length) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/resource/vuetify`,
    params: { page: page, length: length, search: "" },
  });
}
</script>
