<template>
  <v-sheet class="text-start" v-if="packageEntity">
    <page-head
      :title="packageEntity.name"
      sub-head="packages"
      :breadcrumb="['Market', 'Packages', packageEntity.name]"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
      <v-tab value="linkFund">Link Fund</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input
              label="ID"
              :default="packageEntity.id"
              :readonly="true"
            ></patch-input>
            <patch-input
              label="Name"
              patch-key="name"
              :default="packageEntity.name"
              :update-func="onUpdate"
            ></patch-input>
            <patch-input
              label="Description"
              patch-key="description"
              :default="packageEntity.description"
              :update-func="onUpdate"
            ></patch-input>
            <patch-input
              label="Price"
              patch-key="price"
              :default="packageEntity.price"
              :update-func="onUpdate"
            ></patch-input>
            <v-divider class="mb-5"></v-divider>
            <patch-image-file
              label="Image (512 x 512 pixels)"
              patch-key="image"
              :update-func="onUploadImage"
              :image-url="packageEntity.image_link"
            ></patch-image-file>
          </v-container>
        </v-tabs-window-item>
        <v-tabs-window-item value="linkFund">
          <v-container fluid>
            <patch-select
              label="Fund"
              patch-key="linked_member_fund"
              :default="packageEntity.linked_member_fund"
              :items="funds"
              :update-func="onUpdate"
              v-model="fund"
            ></patch-select>
            <v-sheet v-if="fund != 'no_link'">
              <patch-input
                label="Value"
                patch-key="value"
                :default="packageEntity.value"
                :update-func="onUpdate"
              ></patch-input>
              <patch-input
                label="Usage Duration (days)"
                patch-key="usage_duration"
                :default="packageEntity.usage_duration"
                :update-func="onUpdate"
              ></patch-input>
              <patch-input
                label="Unlimited Access Duration (days)"
                patch-key="unlimited_access_duration"
                :default="packageEntity.unlimited_access_duration"
                :update-func="onUpdate"
              ></patch-input> </v-sheet
          ></v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";
import PatchSelect from "@/components/admin_theme/patch/PatchSelect.vue";
import PatchImageFile from "@/components/admin_theme/patch/PatchImageFile.vue";
import BackendConnector from "@/util/BackendConnector";

import { authAxios } from "@/util/Axios";

import { ref } from "vue";
import { useRoute } from "vue-router";

const axios = authAxios();
const route = useRoute();

const tab = ref(null);
const packageEntity = ref(null);
const funds = ref(null);
const fund = ref(null);

const packageId = route.params.packageId;

BackendConnector.loadMemberFund().then((response) => {
  var result = [{ title: "No Link", value: "no_link" }];
  response.data.data.forEach((fund) => {
    result.push({ title: fund.name, value: fund.id });
  });
  funds.value = [...result];
});

loadPackage(packageId).then((response) => {
  packageEntity.value = response.data;
  fund.value = packageEntity.value.linked_member_fund;
});

function loadPackage(packageId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/sale_package/id-${packageId}`,
  });
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/sale_package/id-${packageId}/`,
    data: { [key]: value },
  });
}

function onUploadImage(key, value) {
  var form_data = new FormData();
  form_data.append("upload_file", value);
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/sale_package/id-${packageId}/upload/${key}`,
    data: form_data,
  });
}
</script>
