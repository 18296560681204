<template>
  <app-bar title="Wallet Top-Up" :member-id="$route.params.memberId" />

  <v-sheet color="transparent" class="text-start ma-3">
    <div class="d-flex">
      <div class="flex-grow-1">
        <h4>Current Balance</h4>
        <p class="balance-text">{{ profile.walletAmount }} THB</p>
      </div>
      <div>
        <v-btn class="history-icon" :to="{ name: 'membershipWalletHistory' }"
          ><v-icon icon="mdi-history"></v-icon> History</v-btn
        >
      </div>
    </div>

    <v-text-field
      variant="outlined"
      label="Please enter amount (THB)"
      hint="Minimum amount to top-up 100 THB"
      persistent-hint
      class="mt-5"
      type="number"
      v-model="topUpAmount"
    ></v-text-field>
    <v-sheet
      color="transparent"
      class="d-flex pa-5 my-5 border-sm"
      rounded="lg"
    >
      <div>You will pay</div>
      <div class="ms-auto">{{ topUpAmount }} THB</div>
    </v-sheet>
    <v-btn block @click="onNext">Next</v-btn>
  </v-sheet>
  <pay-solution-hidden-form ref="paySoForm" :pay-so-data="paySoData" />
</template>

<script setup>
import AppBar from "@/components/AppBar.vue";
import PaySolutionHiddenForm from "@/components/PaySolutionHiddenForm.vue";
import { useProfile } from "@/util/MemberProfile";
import PaySolution from "@/util/PaySolution";
import axios from "axios";
import { defineProps, reactive, ref } from "vue";
import { useRoute } from "vue-router";

const props = defineProps(["ui"]);
const route = useRoute();
const topUpAmount = ref(null);
const paySoData = reactive({
  orderNo: "",
  merchantId: "",
  customerEmail: "",
  productDetail: "",
  netPrice: 0,
});
const paySoForm = ref(null);

const profile = ref({});
useProfile(route.params.memberId).then((response) => {
  profile.value = response;
});

function onNext() {
  let orderNo = PaySolution.generateOrderNo();
  paySoData.orderNo = orderNo;
  paySoData.merchantId = props.ui.paySolutionMerchantId;
  paySoData.customerEmail = profile.value.email || "empty@email.com";
  paySoData.productDetail = "Wallet Top-up";
  paySoData.netPrice = topUpAmount.value;
  paySoData.returnUrl = generateReturnUrl(
    route.params.shopId,
    route.params.memberId,
    orderNo
  );
  saveTopUpTransaction(route.params.memberId, topUpAmount.value, orderNo).then(
    () => {
      paySoForm.value.$el.submit();
    }
  );
}

function generateReturnUrl(shopId, memberId, orderNo) {
  return `${window.location.origin}/membership/shop-${shopId}/id-${memberId}/top-up/id-${orderNo}/summary`;
}

function saveTopUpTransaction(memberId, amount, refNo) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/wallet_top_up`,
    data: { amount, reference_no: refNo },
  });
}
</script>

<style scoped>
.balance-text {
  font-size: 2rem;
}
</style>
