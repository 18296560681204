<template>
  <app-bar title="Facility Form" :member-id="$route.query.memberId" />

  <v-overlay :model-value="isLoading" class="align-center justify-center">
    <v-progress-circular color="white" size="64" indeterminate />
  </v-overlay>

  <div class="booking-card d-flex flex-column flex-grow-1">
    <div>
      <v-select
        v-if="branchs.length > 1"
        v-model="selectedBranch"
        label="Branch"
        :items="branchs"
        variant="solo"
        prepend-inner-icon="mdi-map-marker"
      />
    </div>
    <transition name="fade">
      <div>
        <v-date-input
          v-if="stepReach('BRANCH_SELECTED')"
          v-model="selectedDate"
          label="Date"
          variant="solo"
          :hide-actions="true"
          prepend-icon=""
          prepend-inner-icon="$calendar"
          :allowed-dates="allowedDates"
        />
      </div>
    </transition>
    <transition name="fade">
      <div>
        <v-select
          v-if="stepReach('DATE_SELECTED')"
          v-model="selectedResource"
          label="Resource"
          :items="resources"
          variant="solo"
          prepend-inner-icon="mdi-land-plots"
        />
      </div>
    </transition>
    <transition name="fade">
      <div>
        <v-number-input
          v-if="stepReach('RESOURCE_SELECTED')"
          :reverse="false"
          controlVariant="stacked"
          label="Amount"
          :hideInput="false"
          :inset="false"
          variant="solo"
          :min="1"
          :max="4"
          v-model="selectedAmount"
        >
          <template #prepend-inner
            ><v-icon
              icon="mdi-checkbox-multiple-blank-outline"
              class="ms-3"
            ></v-icon></template
        ></v-number-input>
      </div>
    </transition>
    <transition name="fade">
      <div>
        <v-select
          v-if="stepReach('AMOUNT_SELECTED')"
          v-model="selectedTime"
          label="Time"
          :items="times"
          variant="solo"
          prepend-inner-icon="mdi-clock-outline"
          multiple
          chips
        ></v-select>
      </div>
    </transition>
    <v-spacer></v-spacer>
    <transition name="fade">
      <div>
        <v-btn
          v-if="stepReach('TIME_SELECTED')"
          class="booking-submit-btn"
          block
          size="large"
          :color="props.ui.secondaryColor"
          @click="submitFacilityInfo"
        >
          Book
        </v-btn>
      </div>
    </transition>
  </div>
</template>

<script setup>
import AppBar from "@/components/AppBar.vue";
import { reactive, ref, watch, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";
import BackendConnector from "@/util/BackendConnector";
import moment from "moment";

const props = defineProps(["ui"]);
const route = useRoute();
const router = useRouter();

const isLoading = ref(false);
const branchs = reactive([]);
const allowedDates = reactive([]);
const times = reactive([]);
const resources = reactive([]);

const selectedBranch = ref(null);
const selectedDate = ref(null);
const selectedResource = ref(null);
const selectedAmount = ref(null);
const selectedTime = ref(1);

const STEP = [
  "NOTHING_SELECTED",
  "BRANCH_SELECTED",
  "DATE_SELECTED",
  "RESOURCE_SELECTED",
  "AMOUNT_SELECTED",
  "TIME_SELECTED",
];
const currentStep = ref(0);

isLoading.value = true;
BackendConnector.loadBranchs(route.params.shopId).then((response) => {
  Object.assign(branchs, [...response]);
  if (branchs.length == 1) {
    selectedBranch.value = response[0].value;
  }
  isLoading.value = false;
});

watch(selectedBranch, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  selectedDate.value = null;
  selectedResource.value = null;
  selectedAmount.value = null;
  selectedTime.value = null;
  isLoading.value = true;
  BackendConnector.loadFacilityDates(newValue).then((response) => {
    isLoading.value = false;
    Object.assign(allowedDates, [...response.data]);
    currentStep.value = STEP.indexOf("BRANCH_SELECTED");
  });
});

watch(selectedDate, (newValue) => {
  currentStep.value = STEP.indexOf("BRANCH_SELECTED");
  selectedResource.value = null;
  selectedAmount.value = null;
  selectedTime.value = null;
  isLoading.value = true;
  BackendConnector.loadFacilityResources(selectedBranch.value, newValue).then(
    (response) => {
      isLoading.value = false;
      var result = [];
      response.data.forEach((element) => {
        result.push({ title: element.name, value: element.id });
      });
      Object.assign(resources, [...result]);
      currentStep.value = STEP.indexOf("DATE_SELECTED");
    }
  );
});

watch(selectedResource, () => {
  currentStep.value = STEP.indexOf("RESOURCE_SELECTED");
  selectedAmount.value = null;
  selectedTime.value = null;
});

watch(selectedAmount, (newValue) => {
  if (newValue == 0 || newValue == null) {
    return;
  }
  currentStep.value = STEP.indexOf("TIME_SELECTED");
  selectedTime.value = null;
  isLoading.value = true;
  BackendConnector.loadFacilityTimes(
    selectedResource.value,
    selectedBranch.value,
    selectedDate.value,
    newValue
  ).then((response) => {
    isLoading.value = false;
    var result = [];
    response.data[0].forEach((element) => {
      let s_time = moment(element.start_time, "HH:mm:ss").format("HH:mm");
      let e_time = moment(element.end_time, "HH:mm:ss").format("HH:mm");
      result.push({
        title: `${s_time} - ${e_time} (${element.price} THB)`,
        value: [s_time, e_time],
      });
    });
    if (result.length > 0) {
      Object.assign(times, [...result]);
    } else {
      times.length = 0;
    }
    currentStep.value = STEP.indexOf("AMOUNT_SELECTED");
  });
});

watch(selectedTime, () => {
  currentStep.value = STEP.indexOf("TIME_SELECTED");
});

function submitFacilityInfo() {
  isLoading.value = true;
  let startTime = [];
  let endTime = [];
  selectedTime.value.forEach((t) => {
    startTime.push(t[0]);
    endTime.push(t[1]);
  });
  BackendConnector.makeFacilityBooking(
    selectedBranch.value,
    selectedResource.value,
    route.query.memberId,
    moment(selectedDate.value).format("YYYY-MM-DD"),
    startTime,
    endTime,
    selectedAmount.value
  )
    .then((response) => {
      if (response) {
        router.push({
          name: "facilitySummary",
          params: { bookingId: response.data.booking_id },
        });
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
}

function stepReach(step) {
  return currentStep.value >= STEP.indexOf(step);
}
</script>

<style scoped>
.booking-card {
  margin: 2rem 1rem;
}

.booking-submit-btn {
  margin-top: 2rem !important;
}
</style>
