<template>
  <v-dialog :model-value="props.value" width="auto" min-width="340" persistent>
    <v-card prepend-icon="mdi-text-box-plus-outline" title="Make new Booking">
      <v-sheet class="d-flex flex-column ma-3">
        <v-form ref="form" @submit.prevent>
          <auto-complete-member v-model="selectedMember"></auto-complete-member>
        </v-form>
      </v-sheet>
      <template #actions>
        <v-btn class="ms-auto" text="Cancel" @click="emits('cancelBooking')" />
        <v-btn text="Make Booking" @click="onMakeBookingSubmit" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import BackendConnector from "@/util/BackendConnector.js";
import AutoCompleteMember from "./AutoCompleteMember.vue";

const props = defineProps(["value", "eventId"]);
const emits = defineEmits(["makeBooking", "cancelBooking"]);
const form = ref(null);
const selectedMember = ref(null);

async function onMakeBookingSubmit() {
  const { valid } = await form.value.validate();
  console.log(props.eventId, selectedMember.value);
  if (valid) {
    BackendConnector.makeClassBooking(props.eventId, selectedMember.value).then(
      () => emits("makeBooking")
    );
  }
}
</script>
