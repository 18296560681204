<template>
  <v-sheet
    class="d-flex ma-5 pa-3 rounded-lg align-center unlimited-card"
    elevation="4"
    min-width="200"
    v-if="props.fund.isUnlimitedAccess"
  >
    <div class="text-start flex-grow-1 pe-3">
      <p class="fund-title-text">{{ props.fund.name }}</p>
      <v-divider></v-divider>
      <p>valid: {{ props.fund.unlimitedAccessEndDate }}</p>
    </div>
    <div>
      <p class="fund-point-text">
        <v-icon icon="mdi-star-circle-outline"></v-icon>
      </p>
    </div>
  </v-sheet>
  <v-sheet
    class="d-flex ma-5 pa-3 rounded-lg align-center"
    elevation="4"
    min-width="200"
    v-else
  >
    <div class="text-start flex-grow-1 pe-3">
      <p class="fund-title-text">{{ props.fund.name }}</p>
      <v-divider></v-divider>
      <p>valid: {{ props.fund.expireDate }}</p>
    </div>
    <div>
      <p class="fund-point-text">{{ props.fund.value }} pt.</p>
    </div>
  </v-sheet>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["fund"]);
console.log(props.fund);
</script>

<style scoped>
.unlimited-card {
  filter: contrast(120%);
}

.fund-title-text {
  font-weight: 600;
  font-size: 1.2rem;
}
.fund-point-text {
  font-size: 2rem;
}
</style>
