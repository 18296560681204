<template>
  <app-bar title="Facility History" :member-id="$route.params.memberId" />
  <v-list class="bg-transparent text-start" lines="two">
    <v-list-item
      v-for="booking in bookingList"
      :key="booking.booking_id"
      :title="`${booking.resource} (${booking.startTimeStr})`"
      :subtitle="moment(booking.date).format('MMM DD, YYYY')"
    >
      <template #prepend>
        <v-icon icon="mdi-calendar" class="list-prepend-icon"></v-icon>
      </template>
    </v-list-item>
  </v-list>
  <p v-if="bookingList.length == 0" class="mt-5">No Facility Booked</p>
</template>

<script setup>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import BackendConnector from "@/util/BackendConnector";
import { ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const bookingList = ref([]);

BackendConnector.loadFacilityBookingHistory(route.params.memberId).then(
  (response) => {
    bookingList.value = response.data;
    bookingList.value.forEach((booking) => {
      let t = [];
      booking.start_time.forEach((element) => {
        t.push(moment(element, "HH:mm:ss").format("HH:mm"));
      });
      booking.startTimeStr = t;
    });
  }
);
</script>

<style scoped>
.list-prepend-icon {
  font-size: 2.4rem;
}
</style>
